import restAPI from "../../../features/restAPI";
import pages from "../../../routing/config";
import React, {useState, useEffect, useContext} from 'react';
import {useNavigate, NavLink} from "react-router-dom";
import {AuthContext} from "../AuthContext";
import jwt_decode from "jwt-decode";

import "../../../assets/old_css/style.css";


const Login = () => {
    /*
    Login component. This is first page in app, when user not auth.

    All USER LOGIN logic you can find in AuthContext and know how work login and Auth system as well.
    */
    const navigate = useNavigate();
    const {authContext, login} = useContext(AuthContext);
    // const initialState = {username: "zelenchuk.serhii@gmail.com", password: "Empire229",}
    const initialState = {username: "", password: "",}

    const [formData, setFormData] = useState(initialState);

    const [authError, setAuthError] = useState(null);  // state for login error
    const [isLogin, setIsLogin] = useState(authContext);  // local component state for auth
    const [isSubmit, setIsSubmit] = useState(false);  // local component state for auth

    const [tokens, setTokens] = useState(null);
    const [providerFullData, setProviderFullData] = useState(null);


    const onSubmitHandler = async event => {
        event.preventDefault();
        setIsSubmit(true);
    }


    // main login logic. We're making two request and create one object for this application.
    useEffect(() => {

        async function validateUserCredentials() {
            try {
                const response = await restAPI.post('/token/', formData);
                setTokens({...response.data});
            } catch (error) {

                if (error.hasOwnProperty('response')) {
                    setAuthError(error.response?.data?.detail);
                    setIsSubmit(false);
                } else {
                    setAuthError("Error. Check your username and password");
                    setIsSubmit(false);
                    console.error(error);
                }
            }
        }

        // Shame on me, but I don't have time for refactoring this code.
        async function getAllProviderData() {
            try {
                const tokenData = jwt_decode(tokens.access);

                const authConfig = {
                    headers: {
                        Authorization: `ECL ${tokens.access}`,
                    },
                };

                const response = await restAPI.get(`/patients/all/?user_id=${tokenData.user_id}`, authConfig);

                // console.log(response);

                if (response?.data?.count === 0) {
                    // check if user not patient - we break code flow on it
                    // and isSubmit - change state to false for unlock form items.
                    setIsSubmit(false);
                    // eslint-disable-next-line
                    throw "We can't find your account. Sorry 🙏";
                }

                // If all is fine -> we're pushing API response data to local state
                setProviderFullData(response.data.results[0]);
            } catch (error) {

                if (error.hasOwnProperty('response')) {
                    // this condition can be true if auth user don't have permission do this action
                    setAuthError(error.response?.data?.detail);
                } else {
                    setAuthError("Error. Check your username and password");
                    setIsSubmit(false);
                    console.error(error);
                }

            }
        }

        async function makeAuth() {
            try {
                login({...tokens}, {...providerFullData});  // save to AuthProvider
                setIsLogin(true);  // update Auth state in component, for redirect
            } catch (error) {
                console.error(error);
            }
        }

        if (isSubmit) {

            if (tokens === null) {
                validateUserCredentials();
            } else if (providerFullData === null) {
                getAllProviderData();
            } else if (tokens && providerFullData) {
                makeAuth();
            }
        }
        // eslint-disable-next-line
    }, [isSubmit, tokens, providerFullData]);


    // Redirect controller
    useEffect(() => {
        navigate('/'); // This need for control all others links, when we are not AUTH yet

        if (isLogin) {
            navigate('/');  // move to main page after successful auth
        }
        // eslint-disable-next-line
    }, [navigate, isLogin]);

    return <div className="container">
        <div className="wrapper">
            <div className="signin">
                <div className="jbox-dark-sm">

                    <div className="jbox-dark-header">
                        <p>Welcome to Efunctional</p>
                    </div>

                    <hr className="solid"/>

                    <div className="jbox-dark-paragraph">
                        <p>Please log in to your account</p>
                        <br/>
                        <br/>
                    </div>

                    {authError && <>
                        <p style={{color: "red", textAlign: "center", background: 'white', padding: '7px 0'}}>
                            {authError}
                        </p>
                    </>}


                    <form
                        onSubmit={onSubmitHandler}
                        autoComplete="off"
                        className="input-group"
                        id="signin-patient"
                    >

                        <input
                            disabled={isSubmit}
                            autoComplete="off"
                            onChange={({target: {value}}) => setFormData(prevState => {
                                return {...formData, username: value};
                            })}
                            value={formData.username || ""}
                            className="input-field"
                            id="signin-form-login"
                            type="text"
                            name="login"
                            required
                            placeholder="Email"
                        />

                        <input
                            disabled={isSubmit}
                            onChange={({target: {value}}) => setFormData(prevState => {
                                return {...formData, password: value};
                            })}
                            value={formData.password || ""}
                            autoComplete="off"
                            className="input-field"
                            id="signin-form-password"
                            type="password"
                            name="password"
                            required
                            placeholder="Password"
                        />

                        <button className="submit-btn" id="login" type="submit" disabled={isSubmit}>
                            Log In
                        </button>

                        {isSubmit && <p style={{textAlign: 'center', marginTop: 30, color: 'white'}}>
                            loading ...
                        </p>}

                        <div className="jbox-dark-paragraph">
                            <NavLink to={pages.resetPassword.url} style={{color: 'white'}}>
                                <p>Forgot password?</p>
                            </NavLink>
                        </div>

                        <div className="jbox-dark-paragraph">
                            <NavLink to={pages.register.url} style={{color: 'white'}}>
                                <p>Create new account</p>
                            </NavLink>
                        </div>

                    </form>


                    <form
                        onSubmit={onSubmitHandler}
                        className="input-group-phone"
                        id="signin-patient-phone"
                    >

                        <input
                            value={formData.username || ""}
                            onChange={({target: {value}}) => setFormData(prevState => {
                                return {...formData, username: value};
                            })}
                            className="input-field-phone"
                            id="signin-form-login-phone"
                            type="text" name="login"
                            required="required"
                            placeholder="Login"
                        />

                        <input
                            disabled={isSubmit}
                            onChange={({target: {value}}) => setFormData(prevState => {
                                return {...formData, password: value};
                            })}
                            value={formData.password || ""}
                            className="input-field-phone"
                            id="signin-form-password-phone"
                            type="password"
                            name="password"
                            required="required"
                            placeholder="Password"
                        />

                        <button className="submit-btn-phone" id="login-phone" type="submit">Log In</button>


                        <div className="jbox-dark-paragraph">
                            <NavLink to={pages.resetPassword.url} style={{color: 'white'}}>
                                <p>Forgot password?</p>
                            </NavLink>
                        </div>

                        <div className="jbox-dark-paragraph">
                            <NavLink to={pages.register.url} style={{color: 'white'}}>
                                <p>Create new account</p>
                            </NavLink>
                        </div>


                    </form>

                </div>
            </div>
        </div>
    </div>;
}
export default Login;


