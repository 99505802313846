import React, {useEffect} from "react";
import restAPI from "../../../features/restAPI";

import {Col, Row, Button} from "antd";


const EF_TRAVALAB_LINK = "https://travalab.com/e-functional";
const AR_TRAVALAB_LINK = "https://travalab.com/age-rejuvenation";


const CheckTestCode = (props) => {
    /* In general, we need to check a current Kit test kind and use this step */

    const {detectedKitInfo, isLoading, setIsLoading, next, authConfig, detectedProduct, setDetectedProduct} = props;

    const getAllProductsInfo = async () => {
        setIsLoading(true);

        try {

            const response = await restAPI.get(`/products/`, authConfig);
            const detectedProductItem = response.data.results.filter(p => p.code === detectedKitInfo.test);

            if (detectedProductItem.length === 1) {
                setDetectedProduct(detectedProductItem[0]);
            } else {
                throw Error(`We can't find product associated with your Kit. Please call to support.`);
            }

        } catch (error) {

            console.log(error);

        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await getAllProductsInfo();
        })();
        // eslint-disable-next-line
    }, []);


    const IsMicronutrient = () =>

        <Row>
            <Col lg={24}>
                <p>
                    You are about to activate your
                    Efunctional {["AF", "AM"].includes(detectedKitInfo?.orderItem?.product_string) ? "PTSMC" : detectedKitInfo?.orderItem?.product?.name} TEST
                    KIT.
                </p>

                <p>
                    This Test Kit requires a phlebotomist to collect your sample.
                </p>

                <Row>
                    {detectedKitInfo?.isSelfPhlebotomy
                        ? <Col>
                            <p>
                                Your <strong>Provider</strong> will collect your sample and record
                                the collection <strong>date and time</strong>.
                            </p>

                            <p>
                                If your sample has been collected you can click <strong>Next</strong> to proceed
                            </p>

                            <div style={{margin: "24px 0"}}>
                                <Button loading={isLoading} type="primary" onClick={next}>
                                    Next
                                </Button>
                            </div>
                        </Col>

                        : <Col>
                            <p>
                                To begin the process for mobile phlebotomy, please click&nbsp;&nbsp;
                                
                                <Button
                                    size={'small'}
                                    color={'primary'}
                                    type={'link'}
                                    target={'_blank'}
                                    href={
                                        ["AF", "AM"].includes(detectedKitInfo?.orderItem?.product_string)
                                            ? AR_TRAVALAB_LINK
                                            : EF_TRAVALAB_LINK
                                    }
                                >
                                    THIS LINK
                                </Button>
                            </p>

                            <div style={{margin: "24px 0"}}>
                                <Button loading={isLoading} type="primary" onClick={next}>
                                    Next
                                </Button>
                            </div>
                        </Col>
                    }
                </Row>
            </Col>
        </Row>


    const OtherTestThanMN = () =>

        <Row>
            <Col lg={24}>
                <p>
                    You are about to activate your
                    EFunctional {detectedProduct?.name} TEST
                    KIT.
                </p>

                <p>Please follow the instructions on how to self-collect your sample.</p>

                <div style={{margin: "24px 0"}}>
                    <Button loading={isLoading} type="primary" onClick={next}>
                        Next
                    </Button>
                </div>

            </Col>
        </Row>


    if (["AF", "AM", "MN",].includes(detectedKitInfo?.test)) {
        return <IsMicronutrient/>;
    }
    return <OtherTestThanMN/>;
}

export default CheckTestCode;