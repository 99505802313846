import React, {useState, useContext} from 'react';
import {Outlet, NavLink, useLocation} from "react-router-dom";


import {DesktopNavigation, MobileNavigation} from "./Navigation";
import {MainLayoutWrapper, MainContentBlock} from "./components/MainContentBlock";
import HeaderLogoOnMobile from "./components/HeaderLogoOnMobile";

import {AlignRightOutlined, CopyrightOutlined} from "@ant-design/icons";
import {Layout, Row, Col} from "antd";

import pages from "./routing/config";
import {LOGO} from "./assets/images";
import {AuthContext} from "./pages/auth/AuthContext";
import NewVitaminLabBanner from "./NewVitaminLabBanner";
import dayjs from "dayjs";


const {Header, Footer} = Layout;

const MainLayout = () => {
    /* First rendering component in Router config. UI main entrypoint  */

    const [open, setOpen] = useState(false);
    const {authContext} = useContext(AuthContext);
    const {pathname} = useLocation();

    /*
    ===================Important===================
    * This code running before render first content on page and fix render problems.
    * In a bottom we can see all default pages, what works without Auth.
    * */
    if (authContext === null && pathname === pages.home.url) {
        return pages.login.jsx
    } else if (authContext === null && pathname === pages.register.url) {
        return pages.register.jsx
    } else if (authContext === null && pathname === pages.resetPassword.url) {
        return pages.resetPassword.jsx
    }
    /* ========= Default pages controller ========= */


    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    return <Layout style={{overflow: "hidden"}}>

        {/*Main navigation guys*/}
        <MobileNavigation onClose={onClose} open={open}/>
        <DesktopNavigation/>
        {/*Main navigation guys*/}


        {/*Main content Layout --- START*/}
        <MainLayoutWrapper>

            {/*<NewVitaminLabBanner/>*/}

            {/* Header used for Mobile only --- START */}
            <Row>
                <Col md={0} xs={24}>
                    <Header style={{overflow: "hidden", height: "auto", padding: 0}}>

                        <div style={{display: "flex", alignContent: 'end', justifyContent: 'end'}}>

                            <NavLink to={pages.home.url}>
                                <HeaderLogoOnMobile image_source={LOGO}/>
                            </NavLink>

                            <AlignRightOutlined
                                onClick={showDrawer}
                                style={{
                                    color: 'white', fontSize: '240%', padding: '25px 20px 0 0', cursor: "pointer"
                                }}
                            />

                        </div>
                    </Header>
                </Col>
            </Row>
            {/* Header used for Mobile only --- END*/}

            {/* Main block for any pages in our app --- START*/}
            <MainContentBlock>
                <Outlet/>
            </MainContentBlock>
            {/* Main block for any pages in our app --- END */}

            <Footer style={{textAlign: 'center', fontSize: 12}}>
                Patient portal, Efunctional LLC <CopyrightOutlined style={{fontSize: 12}}/>, 2023
                - {dayjs().format('YYYY')}
            </Footer>

        </MainLayoutWrapper>
        {/*Main content Layout --- END */}

    </Layout>;
}


export default MainLayout;
