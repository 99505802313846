import React, {useContext, useState, useEffect} from "react";
import {useParams, useNavigate} from 'react-router-dom';
import MainPageTitle from "../../../components/MainPageTitle";


import {AuthContext} from "../../auth/AuthContext";
import restAPI from "../../../features/restAPI";
import pages from "../../../routing/config";

import {Col, Row, Spin, message} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import SkinTestQuizComponent from "./SkinTestQuizComponent";


const SkinTestQuizPage = () => {
    const {skinTestQuizPage} = pages;

    const navigate = useNavigate();
    const {kitBarcode} = useParams();


    const [isLoading, setIsLoading] = useState(false);
    const {authContext} = useContext(AuthContext); // auth data from current session

    const authConfig = {
        headers: {
            Authorization: `ECL ${authContext?.access}`,
        },
    };


    const [productsList, setProductsList] = useState([]);

    const loadProductList = async () => {
        setIsLoading(true);

        await restAPI.get('/products/', authConfig)
            .then(response => {

                const customProductsForFilteringComponent = response?.data?.results.map(
                    product => ({
                        ...product,
                        label: product?.name,
                        value: product?.name,
                        key: product?.id,
                    }));

                const addShowAll = [{
                    key: 1000,
                    label: 'Show all',
                    value: 'all',
                    code: 'all'
                }, ...customProductsForFilteringComponent];

                setProductsList(addShowAll);

            }).catch(err => {
                console.log(err);
                setProductsList([]);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }


    const [open, setOpen] = useState(false);
    const [childrenDrawer, setChildrenDrawer] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const showChildrenDrawer = () => {
        setChildrenDrawer(true);
    };
    const onChildrenDrawerClose = () => {
        setChildrenDrawer(false);
    };

    const onClick = ({key}) => {
        message.info(`Click on item ${key}`);
    };

    // Check if 'kit' parameter is missing
    if (!kitBarcode) {
        navigate(pages.notFoundPage.url);
    }

    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>

        <Row style={{padding: "0 10px"}}>


            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>
                    {skinTestQuizPage.title}
                </MainPageTitle>

                {/*<pre>*/}
                {/*    <strong>debug:</strong> {kitBarcode}*/}
                {/*</pre>*/}
            </Col>


            <Col xl={24}>
                <SkinTestQuizComponent
                    kitBarcode={kitBarcode}
                    patientData={authContext?.patientData}
                    authConfig={authConfig}
                />
            </Col>

        </Row>
    </Spin>
}

export default SkinTestQuizPage;