import React, {useEffect} from "react";
import {Route, Routes, useLocation} from "react-router-dom";

import pages from "./config";
import MainLayout from "../MainLayout";

const Root = () => {
    const location = useLocation();

    // eslint-disable-next-line
    const {
        activateKit,
        home,
        myInformation,
        notFoundPage,
        login,
        logout,
        register,
        skinTestQuizPage
    } = pages;

    useEffect(() => {
        try {
            const page = Object.values(pages).find(p =>
                p.hasOwnProperty('title') && p.url === location.pathname
            );

            document.title = `${page.title} | Patient portal | EFunctional`;
        } catch (err) {
            // Do nothing.
        }
    }, [location]);

    return <Routes>
        <Route element={<MainLayout/>}>

            {/*Basic pages*/}
            <Route path={notFoundPage.url} element={notFoundPage.jsx}/>
            <Route path={login.url} element={login.jsx}/>
            <Route path={logout.url} element={logout.jsx}/>
            <Route path={register.url} element={register.jsx}/>
            {/*Basic pages*/}


            {/* Auth required pages */}
            <Route path={home.url} element={home.jsx}/>
            <Route path={myInformation.url} element={myInformation.jsx}/>
            {/*<Route path={newOrder.url} element={newOrder.jsx}/>*/}
            {/*<Route path={history.url} element={history.jsx}/>*/}
            {/*<Route path={inventory.url} element={inventory.jsx}/>*/}
            <Route path={activateKit.url} element={activateKit.jsx}/>
            <Route path={skinTestQuizPage.url} element={skinTestQuizPage.jsx}/>
            {/* Auth required pages */}
        </Route>
    </Routes>;
}

export default Root;