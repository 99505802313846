import React from 'react';
import styled from "styled-components";
import {Layout} from "antd";

const MainLayoutWrapper = styled(Layout)`

  & * {
    font-family: 'Montserrat', sans-serif;
  }

  @media only screen and (min-width: 920px) {
    margin-left: 260px !important;
  }

  @media only screen and (min-width: 575px) {
    margin-left: 260px !important;
  }
`

const BrandBlock = styled.div`
    display: block;
    background: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 10px 10px 15px 1px rgba(69, 90, 100, 0.048);
    margin: 100px 50px 0 50px;

    @media only screen and (max-width: 920px) {
        margin: 5px;
        z-index: 120;
    }

    @media only screen and (max-width: 575px) {
        margin: 5px;
        z-index: 120;
    }
`

const LeftPart = styled.div`
  z-index: 1;
  height: 400vh;
  width: 50px;
  background: linear-gradient(to right, #01a9ac, #01dbdf);
  border-top-left-radius: 5px;
  position: absolute;
  left: 0;

  @media only screen and (max-width: 920px) {
    width: 5px;
  }

  @media only screen and (max-width: 575px) {
    width: 5px;
  }
`

const RightPart = styled.div`
        min-height: 82vh;
  z-index: 2;
  width: 100%;
  position: relative;
  overflow: hidden !important;
  background: transparent;

  padding: 0 10px 20px 75px;
  border-bottom-left-radius: 5px;

  @media only screen and (max-width: 920px) {
    padding: 0 0 0 10px;
  }

  @media only screen and (max-width: 575px) {
    padding: 0 0 0 10px;
  }
`


const MainContentBlock = ({children}) =>
    <BrandBlock>
        <RightPart>
            <LeftPart/>
            {children}
        </RightPart>
    </BrandBlock>;


export {MainLayoutWrapper, MainContentBlock}