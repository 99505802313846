import React, {useState, useEffect, useContext} from "react";
import {useNavigate} from "react-router-dom";
import useLocalStorage from "../../../features/hooks/useLocalStorage";
import {AuthContext} from '../AuthContext';


const getAuthContext = () => {
    /* This function get current localstorage object by key - authContext.
    Result this function is an init state for AuthContex */

    const authContextString = localStorage.getItem('authContext');
    return JSON.parse(authContextString) || null;
}

const AuthRequired = ({children}) => {
    /* Custom Auth controller component  */

    const navigate = useNavigate();
    const {authContext} = useContext(AuthContext);

    useEffect(() => {
        if (authContext === null) {
            navigate('/login');
        }
    }, [authContext]);

    return children;
}

const AuthProvider = ({children}) => {
    const [isAuth, setIsAuth] = useLocalStorage('authContext', getAuthContext()); // get default value from LocalStorage

    const [authContext, setAuthContext] = useState(isAuth);

    const login = async (tokens, patientData) => {
        /*
        This function waiting from Login component two params:
        @tokens object
        @patientData object   */

        setIsAuth({...tokens, patientData});  // save to localstorage
        setAuthContext({...tokens, patientData}); // save tokens and parse user_id
    }

    const authConfig = {
        /* headers object for all secure requests. `ECL` it's not standard, but it's custom token for security. */

        headers: {
            Authorization: `ECL ${authContext?.access}`,
        },
    };

    const updatePatientData = newStateData => {

        setIsAuth(prevState => {
            const {patientData} = prevState;
            return {...prevState, patientData: {...patientData, ...newStateData}};
        });

    }

    const createShippingInfo = newShippingData => {

        setIsAuth(prevState => {
            const {patientData} = prevState;

            return {
                ...prevState, patientData: {
                    ...patientData, shipping_addresses: [{...newShippingData}]
                }
            };
        });

    }

    const updateShippingInfo = newShippingData => {

        setIsAuth(prevState => {
            const {patientData} = prevState;
            const {shipping_addresses} = patientData;
            const filteredShippingInfos = shipping_addresses.filter(address => address.default !== true);  // at this moment I'm creating only for `default` address

            return {
                ...prevState, patientData: {
                    ...patientData, shipping_addresses: [{...newShippingData}, ...filteredShippingInfos]
                }
            };
        });

    }

    const logout = () => {
        /* Logout function. Clear all localstorage keys and turn off AuthContex */
        setAuthContext(null); // change react context
        setIsAuth(null); // change localstorage
    }

    return <AuthContext.Provider
        value={
            {
                authContext, authConfig,
                login, logout,
                updatePatientData,
                updateShippingInfo, createShippingInfo,
            }
        }>
        {children}
    </AuthContext.Provider>
}


export {AuthProvider, AuthRequired}