import React, {useState} from 'react';
import {Row, Col, Button, Alert} from "antd";
import restAPI from "../../../features/restAPI";
import pages from "../../../routing/config";
import dayjs from "dayjs";
import {NavLink} from "react-router-dom";

const ShowAllInfo = ({
                         detectedKitInfo,
                         isLoading,
                         setIsLoading,
                         authContext,
                         authConfig,
                         barcode,
                         collectionDate,
                         setIsActivated,
                         patientWeight
                     }) => {

    const [errorMessage, setErrorMessage] = useState(null);


    const backendCheckingBeforeActivation = () => {
        /* Important checking all needed data for successful activation process */

        // const shippingInfo = null; // make errors
        // const {patientData} = {};  // make errors


        // HARDCODED FOR AR WEB PROVIDER CUSTOM KIT
        const ordersCode = detectedKitInfo?.orderItem?.product_string === "AF"
            ? "P5595"  // Female Hormone Panel
            : detectedKitInfo?.orderItem?.product_string === "AM"
                ? "P5594"  // Male Hormone Panel
                : null;
        // HARDCODED FOR AR WEB PROVIDER CUSTOM KIT


        const {patientData} = authContext;
        const {barcode} = detectedKitInfo;
        const shippingInfo = patientData?.shipping_addresses.find(s => s.hasOwnProperty('default'));

        const checkingObject = {
            kitID: detectedKitInfo?.id && true,
            barcode: barcode && true,
            patientID: patientData?.id && true,
            lastName: patientData?.last_name && true,
            firstName: patientData?.first_name && true,
            dob: patientData?.date_of_birth && true,
            sex: patientData?.gender && true,
            street: shippingInfo?.address_1 && true,
            city: shippingInfo?.city && true,
            state: shippingInfo?.state && true,
            zip: shippingInfo?.postcode && true,
            phone: patientData?.phone && true,
            email: patientData?.email && true,
            ordrsCode: ordersCode && true,
            collectionDate: collectionDate.toISOString() && true,
        }

        for (const [key, value] of Object.entries(checkingObject)) {

            if (!value) {

                const shippingKeys = ['street', 'city', 'state', 'zip'];
                const userDataKeys = ['lastName', 'firstName', 'dob', 'sex', 'phone', 'email'];

                if (userDataKeys.includes(key)) {
                    const errorMessage = <>
                        <p>
                            We can't continue 'Activation process'. We do not have enough data about you,
                            please <strong>fill in your personal information</strong> and try to activate the kit again.
                        </p>

                        <p>
                            <NavLink to={pages.myInformation.url}>
                                <Button type={'primary'}>Add information</Button>
                            </NavLink>
                        </p>

                    </>;
                    setErrorMessage(errorMessage)
                    throw new Error(errorMessage);
                }

                if (shippingKeys.includes(key)) {
                    const errorMessage = <>
                        <p>
                            We can't continue 'Activation process'. We do not have the necessary information about your
                            shipping method.
                        </p>

                        <p>
                            Please update this information and return to activating the kit again.
                        </p>

                        <p>
                            <NavLink to={pages.myInformation.url}>
                                <Button type={'primary'}>Add shipping information</Button>
                            </NavLink>
                        </p>

                    </>;

                    setErrorMessage(errorMessage)
                    throw new Error(errorMessage);
                }

                // console.log("We don't check this field ::: ", key);

            } else {
                console.log(value);
            }
        }
    }

    const activateKit = async () => {
        /*
            1. Send data to EFunctional API.
            2. Update kit status and add new fields from current query data.
            3. If all is good - we have accession file in ECL for ELS.
            4. TODO need something to test success result. W8 for Natalia
        */


        const data = {
            patientID: authContext.patientData.id,
            kitID: detectedKitInfo.id,
            collectionDate: collectionDate.toISOString(), // important format fo backend
            dateActivated: dayjs().toISOString(), // important format fo backend
        };

        // New updates for MN test + weight
        if (detectedKitInfo?.test === "MN") {
            data.patientWeight = parseInt(patientWeight, 10)
        }

        // patientWeight

        // console.log(data);


        try {
            setIsLoading(true);

            backendCheckingBeforeActivation();


            // return false;

            const response = await restAPI.post(`/kits/activate/`, data, authConfig);

            // console.log(response);

            setIsActivated(true);

        } catch (error) {

            console.log(error);

            setErrorMessage(`We have an error. Please try again later.`);

        } finally {
            setIsLoading(false);
        }
    }

    return <Row>
        <Col lg={24}>

            <p>
                Barcode: {barcode}
            </p>

            <p>
                Collection date: {collectionDate.format('MM-DD-YYYY hh:mm A')}
            </p>


            <div style={{margin: "24px 0"}}>

                <Button loading={isLoading} type="primary" onClick={activateKit} disabled={errorMessage}>
                    Activate kit
                </Button>

                &nbsp;&nbsp;for {authContext?.patientData?.last_name} {authContext?.patientData?.first_name}
            </div>

            {errorMessage && <Alert style={{margin: "0 0 30px 0"}} message={errorMessage} type="error"/>}

        </Col>
    </Row>
}

export default ShowAllInfo;