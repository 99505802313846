import styled from 'styled-components';
import pages from "./routing/config";
import {Alert as AntdAlert, Tag, Typography as AntdTypography} from "antd";
import VL_FULL_LOGO_PNG from "./assets/images/vl_full_logo.png";
import React from "react";
import {useLocation} from "react-router-dom";
import mobileDevices from "./features/mobileDevices";

const Alert = styled(AntdAlert)`
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background: #06B1B4;
    padding: 15px 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;

    @media ${mobileDevices.tablet} {
        display: none !important;
    }

    @media ${mobileDevices.laptop} {
        padding: 10px 0;
    }
`;

const Typography = styled(AntdTypography)`
    color: white;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media ${mobileDevices.laptop} {
        font-size: 13px;
    }
`;

const PoweredByContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 0 350px 0 0;

    & > div {
        font-size: 7px;
        text-transform: uppercase;
        margin: 0 0 -5px 50px;
    }

    @media ${mobileDevices.laptop} {
        margin: 0 260px 0 0;
    }

`;

const VLLogo = styled.img`
    width: 60%;
`

const NewVitaminLabBanner = () => {
    const {pathname} = useLocation();
    return pathname === pages.home.url && (
        <Alert
            message={
                <Typography>
                    <Tag color="#008688" style={{fontSize: 16}}>New</Tag>
                    Now offering personalized supplements based on your test results.
                    <PoweredByContainer>
                        <div>Powered by</div>
                        <VLLogo src={VL_FULL_LOGO_PNG} alt="Vitamin Lab Logo"/>
                    </PoweredByContainer>
                </Typography>
            }
            banner
            showIcon={false}
        />
    );
};


export default NewVitaminLabBanner;