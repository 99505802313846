import React, {useState, useContext} from "react";

import pages from "../../../routing/config";
import {AuthContext} from "../../auth/AuthContext";

import MainPageTitle from "../../../components/MainPageTitle";
import ValidateBarcode from "./ValidateBarcode";
import CheckTestCode from "./CheckTestCode";
import AddCollectionDate from "./AddCollectionDate";
import ShowAllInfo from "./ShowAllInfo";
import {NavLink} from "react-router-dom";

import {Col, Row, Steps, Spin, Result, Button} from 'antd';
import {LoadingOutlined} from "@ant-design/icons";


const ActivateKit = () => {
    const {activateKit} = pages;
    const {authContext} = useContext(AuthContext); // auth data from current session

    const [isLoading, setIsLoading] = useState(false);
    const [isActivated, setIsActivated] = useState(false);
    const [barcode, setBarcode] = useState(null);
    const [detectedKitInfo, setDetectedKitInfo] = useState(null);
    const [collectionDate, setCollectionDate] = useState(null);
    const [patientWeight, setPatientWeight] = useState(null);
    const [detectedProduct, setDetectedProduct] = useState(null);
    const [current, setCurrent] = useState(0);  // current step


    const next = () => {
        setCurrent(current + 1);
    };

    const authConfig = {
        headers: {
            Authorization: `ECL ${authContext?.access}`,
        },
    };

    const resetActivationComponentState = () => {
        setBarcode(null);
        setDetectedKitInfo(null);
        setCollectionDate(null);
        setDetectedProduct(null);
        setIsActivated(null);
        setCurrent(0);
    }


    const steps = [
        {
            title: 'Validate barcode',
            content: <ValidateBarcode
                isLoading={isLoading}
                next={next}
                barcode={barcode}
                setBarcode={setBarcode}
                authConfig={authConfig}
                setIsLoading={setIsLoading}
                setDetectedKitInfo={setDetectedKitInfo}
            />,
        },

        {
            title: 'Kit info',
            content: <CheckTestCode
                detectedProduct={detectedProduct} setDetectedProduct={setDetectedProduct}
                isLoading={isLoading} setIsLoading={setIsLoading}
                detectedKitInfo={detectedKitInfo}
                next={next}
                authConfig={authConfig}
            />,
        },
        {
            title: 'Collection info',
            content: <AddCollectionDate
                next={next}
                detectedKitInfo={detectedKitInfo}
                collectionDate={collectionDate}
                setCollectionDate={setCollectionDate}
                setPatientWeight={setPatientWeight}
                patientWeight={patientWeight}
            />,
        },

        {
            title: 'Finish',
            content: <ShowAllInfo
                sLoading={isLoading}
                setIsLoading={setIsLoading}
                authConfig={authConfig}
                barcode={barcode}
                detectedKitInfo={detectedKitInfo}
                collectionDate={collectionDate}
                authContext={authContext}
                setIsActivated={setIsActivated}
                patientWeight={patientWeight}
            />,
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>
        <Row style={{padding: "0 10px"}}>

            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>{activateKit.title}</MainPageTitle>
            </Col>

            {isActivated
                ? <Col xs={24} lg={24}>
                    <Result
                        status="success"
                        title="Your Kit is Activated!"
                        subTitle={
                            // `Kit barcode: ${barcode} for test ${detectedProduct?.name}.`}
                            `Kit barcode: ${barcode} for test 
                            ${["AF", "AM"].includes(detectedKitInfo?.orderItem?.product_string)
                                ? "PTSMC"
                                : detectedProduct?.name}.`
                        }
                        extra={[
                            <NavLink to={pages.home.url}>
                                <Button type="primary" key="toResults" style={{margin: '20px 0'}}>
                                    Go To Results
                                </Button>
                            </NavLink>,

                            <Button key="activate" onClick={resetActivationComponentState}>
                                Activate one more kit
                            </Button>,
                        ]}
                    />
                </Col>
                : <Col xs={24} lg={24}>
                    <Row>
                        <Col xs={24} lg={6}>
                            <Steps current={current} items={items} size="small" direction={'vertical'}/>
                        </Col>

                        <Col xs={24} lg={18}>
                            <div>{steps[current].content}</div>
                        </Col>
                    </Row>
                </Col>}

        </Row>
    </Spin>
}

export default ActivateKit;