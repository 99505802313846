import dayjs from "dayjs";
import React, {useState, useEffect} from "react";
import {Button, Col, DatePicker, TimePicker, Row, Form, InputNumber} from 'antd';


const AddCollectionDate = ({
                               next,
                               collectionDate,
                               setCollectionDate,
                               detectedKitInfo,
                               setPatientWeight,
                               patientWeight
                           }) => {

    const [collectionDateState, setCollectionDateState] = useState(null);
    const [collectionTimeState, setCollectionTimeState] = useState(null);

    const onChangeDate = (value, dateString) => {
        console.log('Selected Date: ', dateString);
        // console.log('Selected Date: ', dayjs(collectionDateState).format('MM-DD-YYYY'));
        // console.log('Selected Time: ', collectionTimeState);
        // console.log('Selected Time: ', dayjs(collectionTimeState).format('MM-DD-YYYY'));


        // console.log('Selected Time: ', value);
        // console.log('Formatted Selected Time: ', dateString);


        setCollectionDateState(dateString);
    };

    const onChangeTime = (value, dateString) => {
        console.log('Selected Date: ', dateString);
        // console.log('Selected Date: ', dayjs(collectionDateState).format('MM-DD-YYYY'));
        // console.log('Selected Time: ', collectionTimeState);
        // console.log('Selected Time: ', dayjs(collectionTimeState).format('MM-DD-YYYY'));


        // console.log('Selected Time: ', value);
        // console.log('Formatted Selected Time: ', dateString);


        setCollectionTimeState(dateString);
    };

    const DisabledDate = (current) => {
        /* Disable future days and Saturday and Sunday */

        if (!current) return false;

        // Current date using dayjs
        const today = dayjs().startOf('day');

        // Check if the current day is strictly after today
        if (current.startOf('day').isAfter(today)) {
            return true;
        }

        // Disable Saturdays and Sundays
        return current.day() === 0 || current.day() === 6;
    }

    const DisabledTime = () => {
        /* From 9:00am to 6:59pm */
        return {
            disabledHours: () => {
                const hours = [];
                for (let i = 0; i < 9; i++) {  // Disable 0-8
                    hours.push(i);
                }
                for (let i = 19; i < 24; i++) {  // Disable 19-23
                    hours.push(i);
                }
                return hours;
            },
        };
    }


    const rulesForDateHandler = (current) => {
        /* Rules for our test kits */
        if (detectedKitInfo?.test === "MN") {
            if (!detectedKitInfo?.isSelfPhlebotomy) {
                return DisabledDate(current);
            }
        }

        return null;
    }

    const rulesForTimeHandler = () => {
        /* Rules for our test kits */
        if (detectedKitInfo?.test === "MN") {
            if (!detectedKitInfo?.isSelfPhlebotomy) {
                return DisabledTime();
            }
        }

        return () => null;
    }

    const onOkDateHandler = value => setCollectionDateState(value);
    const onOkTimeHandler = value => setCollectionTimeState(value);

    useEffect(() => {
        console.log(collectionDate);
        setCollectionDate(null);
        console.log(collectionDate);

        let date = collectionDateState || "";
        let time = collectionTimeState || "";
        let current = dayjs(date + time, 'MM-DD-YYYY hh:mm A')


        if (date !== "" && time !== "") {
            console.log(current.toISOString());
            setCollectionDate(current);
        }

    }, [collectionDateState, collectionTimeState]);


    return <Row>
        <Col lg={24}>
            <p style={{margin: ' 0 0 30px 0'}}>
                <strong>
                    Add Collection Date
                </strong>
            </p>

            {detectedKitInfo?.test === "MN"
                ? <></>
                : null
            }


            <Form.Item
                label={'Date'}
            >
                <DatePicker
                    onChange={onChangeDate}
                    format={'MM-DD-YYYY'}
                    // disabledDate={(detectedKitInfo?.test === "MN" && detectedKitInfo?.isSelfPhlebotomy) ? null : DisabledDate}
                    disabledDate={rulesForDateHandler}
                />
            </Form.Item>

            <Form.Item
                label={'Time'}
            >
                <TimePicker
                    // disabledTime={(detectedKitInfo?.test === "MN" && !detectedKitInfo?.isSelfPhlebotomy) ? DisabledTime : null}
                    disabledTime={rulesForTimeHandler}
                    onChange={onChangeTime} format={'hh:mm A'}
                />
            </Form.Item>


            {detectedKitInfo?.test === "MN"
                ? <>
                    <p style={{margin: ' 0 0 30px 0'}}>
                        <strong>
                            Please enter your weight, lbs
                        </strong>
                    </p>

                    <Form.Item
                        label={'Weight (lbs)'}
                        rules={
                            [
                                {
                                    required: true,
                                    message: 'Please enter your weight'
                                }
                            ]
                        }
                    >
                        <InputNumber onChange={(value) => setPatientWeight(value)}/>
                    </Form.Item>

                </>
                : null
            }


            <div style={{margin: "24px 0"}}>
                <Button
                    disabled={detectedKitInfo?.test === "MN" ? (!collectionDate || !patientWeight) : !collectionDate}
                    type="primary"
                    onClick={next}
                >
                    Next
                </Button>
            </div>

        </Col>
    </Row>
}

export default AddCollectionDate;