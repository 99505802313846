import React, {useState, useEffect} from "react";
import restAPI from "../../../features/restAPI";
import dayjs from "dayjs";

import {
    LinkOutlined,
    FilePdfOutlined,
    CloseOutlined,
    WarningOutlined,
    CheckSquareOutlined,
    InfoCircleOutlined,
    ShopOutlined,
    SolutionOutlined
} from "@ant-design/icons";
import {
    Button, DatePicker, Space, Typography, Row, Col, Select, Modal, Alert, Drawer, Form, Divider, Tooltip, Spin, Tag
} from "antd";
import {FinalizedOrdersTable} from "./styled-components/FinalizedTable";

import {LOGO_EFUNC, VIT_LAB} from '../../../assets/images';
import VT_LOGO_PNG from '../../../assets/images/vtlab_logo.png';
import styled from "styled-components";
import VitaminLabsPatientOrderDrawer from "./VitaminLabsPatientOrderDrawer";
import {FoodAllergyReport, RaspReport} from "./DynamicReports";
import {NavLink} from "react-router-dom";


const ElaborateReportModal = styled(Modal)`
    .ant-modal-content {
        padding: 0 !important;
        z-index: 999 !important;

        .ant-modal-content::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #F5F5F5;
        }

        .ant-modal-content::-webkit-scrollbar {
            width: 10px;
            background-color: #F5F5F5;
        }

        .ant-modal-content::-webkit-scrollbar-thumb {
            background-color: #000000;
            border: 2px solid #555555;
        }
    }
`


const PatientDetails = ({authConfig, productsList, patient, setIsLoading}) => {

    const pagination = {position: ["bottom"],};  // table config
    const [kits, setKits] = useState([]);


    // eslint-disable-next-line
    const [month, setMonth] = useState(null);
    const [filterByKits, setFilterByKits] = useState(null);

    // modal and iframe stuf
    const [open, setOpen] = useState(false);  // report modal state
    const [selectedKIT, setSelectedKIT] = useState(null);
    const [isReportLoading, setIsReportLoading] = useState(false);
    const [iframeLink, setIframeLink] = useState(null);
    const [reportError, setReportError] = useState(null);

    // vitamin drawer
    const [isDrawerOpened, setIsDrawerOpened] = useState(false);
    const [isCreateOrderLoading, setIsCreateOrderLoading] = useState(false);
    const [kind, setKind] = useState(null);  // kind of vitamins
    const [daysSupply, setDaysSupply] = useState(null);  // vitamins days supply

    // Declined kit  modal
    const [openDeclinedModal, setOpenDeclinedModal] = useState(false);


    const showDeclinedModal = (currentKit) => {
        setSelectedKIT({...currentKit})
        setOpenDeclinedModal(true);
    };

    const handleDeclinedModalOk = () => {
        handleDeclinedModalCancel();
    };

    const handleDeclinedModalCancel = () => {
        // console.log('Clicked cancel button');
        setOpenDeclinedModal(false);
    };


    const showModal = (currentKit) => {
        setSelectedKIT({...currentKit})
        setOpen(true);
    };

    const handleOk = () => {
        handleCancel();
    };

    const handleCancel = () => {
        // console.log('Clicked cancel button');
        setOpenDeclinedModal(false);
        setOpen(false);
        setSelectedKIT(null);
        setIframeLink(null);
        setReportError(null);
    };

    const openGeneralPdf = () => {
        /* Default PDF-file with explanations for FS and FE test-kits */

        const storageURL = "https://efunctional-static-server.s3.us-east-1.amazonaws.com";
        const fileName = '/static_files/56%20pages%20report.pdf';
        const finalUrl = storageURL + fileName;

        window.open(finalUrl);
    };

    const openPersonalPdf = async (kitID, kitBarcode) => {
        /* Personal PDF-file with explanations for FS and FE test-kits.
           Works with FE, FS, MN test kits.
           Each barcode has its own PDF-file with unique content.
           PDF-url formula: f"{file_name_without_extension}_{kit.id}.pdf" */
        const storageURL = "https://efunctional-static-server.s3.us-east-1.amazonaws.com";
        const fileName = `/mediafiles/patients-pdf-reports/${kitBarcode}_${kitID}.pdf`;
        const finalUrl = storageURL + fileName;

        window.open(finalUrl);
    }


// table columns editing here
    const columns = [

        {
            // width: "30%",
            title: 'Test',
            dataIndex: 'orderItem',
            responsive: ['xs', 'sm'],
            render: (orderItem, currentKit) => <>
                {
                    ["AF", "AM"].includes(currentKit?.test)
                        ? <span>PTSMC</span>
                        : orderItem?.product?.name
                }
            </>,
            ellipsis: true,
        },

        {
            align: 'center',
            title: 'Barcode',
            dataIndex: 'barcode',
            responsive: ['md'],
            render: barcode => <Tag bordered={false}>{barcode}</Tag>,
        },

        {
            align: 'center',
            ellipsis: true,
            title: 'Status',
            dataIndex: 'status',
            responsive: ['xs', 'md'],
            render: status =>
                <>
                    {status === "MDDecline" ?
                        <span style={{color: "red"}}><WarningOutlined/>&nbsp;Declined</span>
                        : status === "LabDecline"
                            ? <span style={{color: "red"}}><WarningOutlined/>&nbsp;Declined</span>
                            : status === 'Final'
                                ? <span style={{color: "#01a9ac"}}><CheckSquareOutlined/>&nbsp;{status}</span>
                                : status
                    }
                </>
        },

        {
            title: 'Activation Date',
            dataIndex: 'dateActivated',
            render: date => <Tag bordered={false}>
                {dayjs(date).isValid() ? dayjs(date).format("MM-DD-YYYY") : 'TBD'}
            </Tag>,
            responsive: ['xl',],
            align: 'center',
        },

        {
            align: 'center',
            title: 'Final Date',
            dataIndex: 'finalDate',
            render: date => <Tag bordered={false}>
                {dayjs(date).isValid() ? dayjs(date).format("MM-DD-YYYY") : 'TBD'}
            </Tag>, responsive: ['xl',],
        },

        {
            align: 'center',
            ellipsis: true,
            title: () => <>
                Results
                {/*<img width={100} src={VIT_LAB} alt={'Vitamins formula'}/>*/}
            </>,
            dataIndex: 'created',
            render: (finalDate, currentKit) =>

                <Row justify={"center"}>

                    {currentKit.status === 'LabDecline'

                        ? <Tooltip placement="left" title={'Reason explanation'} zIndex={10}>
                            <Button
                                danger
                                color={'danger'}
                                icon={<WarningOutlined/>}
                                onClick={() => showDeclinedModal(currentKit)}
                            />
                        </Tooltip>

                        : currentKit.status === 'MDDecline'

                            ? <Tooltip placement="left" title={'Reason explanation'} zIndex={10}>
                                <Button
                                    danger
                                    // type={'primary'}
                                    icon={<WarningOutlined/>}
                                    onClick={() => showDeclinedModal(currentKit)}
                                />
                            </Tooltip>

                            // : currentKit.test === "MN" && currentKit.status === 'Final'
                            : ["MN"].includes(currentKit?.test) && currentKit.status === 'Final'

                                ? <Space>
                                    <Tooltip placement="bottom" title={'Full report'} zIndex={10}>
                                        <Button icon={<LinkOutlined/>} onClick={() => showModal(currentKit)}/>
                                    </Tooltip>

                                    <Tooltip placement="bottom" title={'Personal report'} zIndex={10}>
                                        <Button
                                            icon={<FilePdfOutlined/>}
                                            onClick={() => openPersonalPdf(currentKit.id, currentKit.barcode)}
                                        />
                                    </Tooltip>

                                    {/*{(patient?.id === 15 || patient?.id === 52 || patient?.id === 1256) &&*/}
                                    {/*    <Tooltip*/}
                                    {/*        placement="bottom"*/}
                                    {/*        title={currentKit?.vl_line_code === null ? "In process..." : 'Vitamin Formula'}*/}
                                    {/*        zIndex={10}*/}
                                    {/*    >*/}
                                    {/*        <Button*/}
                                    {/*            disabled={currentKit?.vl_line_code === null}*/}
                                    {/*            icon={*/}
                                    {/*                <div style={{*/}
                                    {/*                    display: 'flex',*/}
                                    {/*                    justifyContent: 'center',*/}
                                    {/*                    alignItems: 'center'*/}
                                    {/*                }}>*/}
                                    {/*                    <img width={22} src={VT_LOGO_PNG} alt={'Vitamins formula'}/>*/}
                                    {/*                </div>*/}
                                    {/*            }*/}
                                    {/*            onClick={() => showDrawerHandler(currentKit)}*/}
                                    {/*        />*/}
                                    {/*    </Tooltip>*/}
                                    {/*}*/}


                                    {/*<pre>*/}
                                    {/*    {JSON.stringify(currentKit, null, 2)}*/}
                                    {/*</pre>*/}

                                    {/*{currentKit?.provider_details?.doctor_npi !== null &&*/}

                                    {/*Button for Patients (PN) can make a direct orders for VL */}
                                    {
                                        (currentKit?.provider_details?.is_physician_network === true
                                        || currentKit?.web_provider !== null)
                                        &&
                                        <Tooltip
                                            placement="bottom"
                                            title={currentKit?.vl_line_code === null ? "In process..." : 'Vitamin Formula'}
                                            zIndex={10}
                                        >
                                            <Button
                                                disabled={currentKit?.vl_line_code === null}
                                                icon={
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        <img width={22} src={VT_LOGO_PNG} alt={'Vitamins formula'}/>
                                                    </div>
                                                }
                                                onClick={() => showDrawerHandler(currentKit)}
                                            />
                                        </Tooltip>
                                    }


                                    {/*
                                        Behavior for direct to consumer
                                    */}
                                    {(
                                            currentKit?.provider === null
                                            && currentKit?.provider_details === null
                                            && currentKit?.web_provider === null
                                        ) &&
                                        <Tooltip
                                            placement="bottom"
                                            title={currentKit?.vl_line_code === null ? "In process..." : 'Vitamin Formula'}
                                            zIndex={10}
                                        >
                                            <Button
                                                disabled={currentKit?.vl_line_code === null}
                                                icon={
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        <img width={22} src={VT_LOGO_PNG} alt={'Vitamins formula'}/>
                                                    </div>
                                                }
                                                onClick={() => showDrawerHandler(currentKit)}
                                            />
                                        </Tooltip>
                                    }
                                </Space>



                                // like Food Allergy test result
                                : ["RP",].includes(currentKit?.test) && currentKit.status === 'Final'
                                    ? <Space>
                                        <RaspReport/>
                                    </Space>


                                    // like Food Allergy test result
                                    : ["FA",].includes(currentKit?.test) && currentKit.status === 'Final'
                                        ? <Space>
                                            <FoodAllergyReport/>
                                        </Space>

                                        : ["AF", "AM",].includes(currentKit?.test) && currentKit.status === 'Final'
                                            ? <Space>


                                                <Alert
                                                    style={{fontSize: 9}}
                                                    icon={<InfoCircleOutlined/>} type="info" showIcon={true}
                                                    message={'Please contact your Health Care Provider'}
                                                />

                                                {/*<Tooltip placement="left" title={'Full report'} zIndex={10}>*/}
                                                {/*    <Button icon={<LinkOutlined/>} onClick={() => showModal(currentKit)}/>*/}
                                                {/*</Tooltip>*/}

                                                {/*<Tooltip placement="right" title={'Personal report'} zIndex={10}>*/}
                                                {/*    <Button*/}
                                                {/*        icon={<FilePdfOutlined/>}*/}
                                                {/*        onClick={() => openPersonalPdf(currentKit.id, currentKit.barcode)}*/}
                                                {/*    />*/}
                                                {/*</Tooltip>*/}

                                            </Space>

                                            : ["FS", "FE"].includes(currentKit?.test) && currentKit.status === 'Final'

                                                ? <Space>
                                                    <Tooltip placement="left" title={'General info'} zIndex={10}>
                                                        <Button
                                                            icon={<FilePdfOutlined/>}
                                                            onClick={openGeneralPdf}
                                                        />
                                                    </Tooltip>

                                                    <Tooltip placement="right" title={'Personal report'} zIndex={10}>
                                                        <Button
                                                            icon={<FilePdfOutlined/>}
                                                            onClick={() => openPersonalPdf(currentKit.id, currentKit.barcode)}
                                                        />
                                                    </Tooltip>
                                                </Space>

                                                : ["CC"].includes(currentKit?.test) && currentKit.status === 'Final' && currentKit?.finalDate >= '2023-12-29'

                                                    ? <Space>
                                                        <Tooltip placement="right" title={'Personal report'}
                                                                 zIndex={10}>
                                                            <Button
                                                                icon={<FilePdfOutlined/>}
                                                                onClick={() => openPersonalPdf(currentKit.id, currentKit.barcode)}
                                                            />
                                                        </Tooltip>
                                                    </Space>

                                                    : ["WM", "CE", "AP"].includes(currentKit?.test) && currentKit.status === 'Final'

                                                        ? <Space>
                                                            <Tooltip placement="right" title={'Personal report'}
                                                                     zIndex={10}>
                                                                <Button
                                                                    icon={<FilePdfOutlined/>}
                                                                    onClick={() => openPersonalPdf(currentKit.id, currentKit.barcode)}
                                                                />
                                                            </Tooltip>
                                                        </Space>

                                                        : ["SK"].includes(currentKit?.test) && currentKit.status === 'Final'

                                                            ? <Space>
                                                                <Tooltip placement="left" title={'Full report'}
                                                                         zIndex={10}>
                                                                    <Button icon={<LinkOutlined/>}
                                                                            onClick={() => showModal(currentKit)}
                                                                    />
                                                                </Tooltip>


                                                                <Tooltip placement="top"
                                                                         title={'Anamnesis for Skin test '}
                                                                         zIndex={10}>
                                                                    <NavLink
                                                                        to={'/skin-test-anamnesis/' + currentKit.barcode}
                                                                        // target={'_blank'}
                                                                    >
                                                                        <Button

                                                                            icon={<SolutionOutlined/>}
                                                                            type={'dashed'}
                                                                        />
                                                                    </NavLink>
                                                                </Tooltip>


                                                                {/*<Tooltip placement="top" title={'Go to portal'}*/}
                                                                {/*         zIndex={10}>*/}
                                                                {/*    <NavLink*/}
                                                                {/*        to={'https://app.essentialapothecaryrx.com/login'}*/}
                                                                {/*        target={'_blank'}*/}
                                                                {/*    >*/}
                                                                {/*        <Button icon={<ShopOutlined/>}*/}
                                                                {/*                type={'dashed'}/>*/}
                                                                {/*    </NavLink>*/}
                                                                {/*</Tooltip>*/}

                                                            </Space>

                                                            : currentKit.test !== "MN" && currentKit.test !== "FE" && currentKit.status === 'Final'

                                                                ?

                                                                <Tooltip placement="left" title={'Full report'}
                                                                         zIndex={10}>
                                                                    <Button icon={<LinkOutlined/>}
                                                                            onClick={() => showModal(currentKit)}
                                                                    />
                                                                </Tooltip>

                                                                : <span>Pending</span>
                    }
                    {/*</Space>,*/}
                </Row>, responsive: ['xs', 'sm', 'md',],
        },

    ];

    const loadPatientsKits = async () => {

        setIsLoading(true);

        try {

            let requestURL = ``; // result API request URL

            // month and kits filter no null and filter not === `all` -> it's mean we must filter for all params
            if (month !== null && filterByKits !== null && filterByKits !== 'all') {
                requestURL = `/kits/all/?patient=${patient.id}&ordering=-created`;
                requestURL += `&test=${filterByKits}`;
                requestURL += `&created__gte=${month.startDate}`;
                requestURL += `&created__lte=${month.endDate}`;
            }

            // only month filter turn ON
            if (filterByKits === null && month !== null) {
                requestURL = `/kits/all/?patient=${patient.id}&ordering=-created`;
                requestURL += `&created__gte=${month.startDate}`;
                requestURL += `&created__lte=${month.endDate}`;
            }

            // only test filter turn ON date - OFF
            if (filterByKits !== null && month === null) {
                requestURL = `/kits/all/?patient=${patient.id}&ordering=-created`;
                requestURL += `&test=${filterByKits}`;
            }

            // Date filter ON and when we have `all` -> selected
            if (month !== null && filterByKits === 'all') {
                requestURL = `/kits/all/?patient=${patient.id}&ordering=-created`;
                requestURL += `&created__gte=${month.startDate}`;
                requestURL += `&created__lte=${month.endDate}`;
            }

            // Date turn OFF and
            if (month === null && filterByKits === 'all') {
                requestURL = `/kits/all/?patient=${patient.id}&ordering=-created`;
            }

            // Date turn OFF and testFiltering OFF
            if (filterByKits === null && month === null) {
                requestURL = `/kits/all/?patient=${patient.id}&ordering=-created`;
                // requestURL = `/kits/all/?ordering=-created&test=CC&status=Final`;
            }

            const response = await restAPI.get(`${requestURL}`, authConfig)


            // add the test kit to the list of kits
            const testKit4DevelopmentOnly1 = {
                id: 9999999,
                barcode: "9999999",
                status: "Final",
                test: "FA", // food allergy
                created: "2023-12-29",
                finalDate: "2023-12-29",
                orderItem: {
                    product: {
                        name: "Food allergy (test) ✅".toUpperCase()
                    }
                }
            }

            const testKit4DevelopmentOnly2 = {
                id: 9999999,
                barcode: "9999999",
                status: "Final",
                test: "RP", // respiratory panel
                created: "2023-12-29",
                finalDate: "2023-12-29",
                orderItem: {
                    product: {
                        name: "Respiratory panel (test) ✅".toUpperCase()
                    }
                }
            }

            // use the test kit for development only, only for the patient with id 15
            if (patient.id === 15) {
                setKits([...response.data.results, testKit4DevelopmentOnly1, testKit4DevelopmentOnly2]);
                return
            }

            // production
            setKits(response.data.results);

        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    const onChangeMonthHandler = value => {
        const currentDate = dayjs(value);

        if (currentDate.isValid()) {
            const startDate = currentDate.startOf('month').format('YYYY-MM-DD');
            const endDate = currentDate.endOf('month').format('YYYY-MM-DD');

            setMonth({startDate, endDate});
            return;
        }
        setMonth(null);
    }

    useEffect(() => {
        (async () => {
            await loadPatientsKits();
        })();
        // eslint-disable-next-line
    }, [filterByKits, month]);


    const getReportLink = async () => {
        /* Elaborate Report */

        const {id, patient, to_date, npi} = selectedKIT;

        const data = {
            "kitID": id,
            "patientID": patient,
            "doctorNPI": npi,  // for Patient, we can check only Patient info.
            to_date,
        };

        try {
            setIsReportLoading(true);

            const response = await restAPI.post(`/results/get-report-link/`, data, authConfig)
            setIframeLink(response.data.secureURL);

        } catch (error) {

            if (error.hasOwnProperty('response')) {
                setReportError(error.response.data.error);
            }

            console.log(error);

        } finally {
            setIsReportLoading(false);
        }

    }


    useEffect(() => {

        if (selectedKIT && open) {

            (async () => {
                await getReportLink();
            })();

        }


    }, [open])


    const handleChange = (value, object) => {
        // console.log(`selected ${object.code}`); // I can use this code for filtering

        setFilterByKits(object.code);
    };

    const showDrawerHandler = currentKIT => {
        setSelectedKIT(currentKIT);
        setIsDrawerOpened(true);
    }

    const onDrawerCloseHandler = () => {
        // console.log('Clicked cancel button');
        setIsDrawerOpened(false);
        setSelectedKIT(null);
        setKind(null);
        setDaysSupply(null);
    }


    return <Row gutter={[24, 24]}>

        <VitaminLabsPatientOrderDrawer
            isDrawerOpened={isDrawerOpened}
            onDrawerCloseHandler={onDrawerCloseHandler}
            selectedKIT={selectedKIT}
        />

        {/*Decline modal */}
        <Modal
            centered={true}
            destroyOnClose={true}
            closable={true}
            width={window.innerWidth > 1200 ? '23%' : '92%'}
            open={openDeclinedModal}
            onOk={handleDeclinedModalOk}
            onCancel={handleDeclinedModalOk}
            footer={false}
        >

            <Row justify={"center"} gutter={[24, 24]} style={{padding: "20px"}}>
                <Col>
                    <p>
                        <strong>
                            Dear {patient?.last_name} {patient?.first_name},
                        </strong>
                    </p>


                    <p>We are very sorry, but your test kit was canceled.</p>

                    <p>
                        At the moment, we do not know the reason for the refusal, but we will definitely find out and
                        inform
                        you about it to your mail.
                    </p>

                    <p>We also sent you instructions for returning funds or re-ordering.</p>


                    <p>
                        Please feel free to reach out to our customer care team with any questions.
                    </p>

                    <p>
                        <strong> All the best, <br/>Efunctional Customer Care Team</strong> <br/>
                        <strong>email</strong>
                        <Button type={'link'} href="mailto:support@efunctional.com">support@efunctional.com</Button>
                        <br/>
                        <strong>phone</strong>
                        <Button type={'link'} href="tel:+18882711717">+1-888-271-1717</Button> <br/>
                        (available M-F 9am - 5pm est)
                    </p>


                </Col>
            </Row>

            <Row justify={"center"} style={{padding: "0 0 20px 0"}}>
                <Button
                    size={'small'}
                    type={'primary'}
                    onClick={handleCancel}
                >
                    I got it
                </Button>
            </Row>

        </Modal>
        {/*Decline modal */}


        {/*Elaborate Modal with IFrame ****---- START ----**** */}
        <ElaborateReportModal
            centered={true}
            destroyOnClose={true}
            closable={true}
            width={reportError ? window.innerWidth > 1200 ? '20%' : '92%' : '95%'}
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
        >

            {reportError && <Spin spinning={isReportLoading}>
                <Row justify={"center"} gutter={[24, 24]} style={{padding: "20px"}}>
                    <Col>
                        <Alert style={{margin: "30px 0 0 0", textAlign: "center"}}
                               message={reportError}/>
                    </Col>
                </Row>
            </Spin>}

            {iframeLink && <iframe

                width={'100%'}
                height={'790px'}
                loading={isReportLoading}
                title={'Elaborate report'}
                src={iframeLink}
            />}

            <Row justify={"center"} style={{padding: "0 0 20px 0"}}>
                <Button
                    icon={reportError ? null : <CloseOutlined/>}
                    size={'small'}
                    type={reportError ? 'primary' : 'default'}
                    onClick={handleCancel}
                >
                    {reportError ? 'Okay' : 'Close report'}
                </Button>
            </Row>

        </ElaborateReportModal>
        {/*Elaborate Modal with IFrame ****---- END ----**** */}

        <Col xs={24} lg={8}>
            <Typography.Text>
                Filter your results:
                {/*<span style={{fontSize: 8}}>*/}
                {/*    {`${month?.startDate} - ${month?.endDate} + ${filterByKits}`}*/}
                {/*</span>*/}
            </Typography.Text>
        </Col>

        <Col xs={24} lg={8}>
            <DatePicker
                placeholder={'Filter by month'}
                style={{width: '100%'}}
                // defaultValue={dayjs()}
                onChange={onChangeMonthHandler}
                format={'MM-YYYY'}
                picker="month"
                inputReadOnly={true}
                size={'small'}
            />
        </Col>

        <Col xs={24} lg={8}>
            <Select
                size={'small'}
                style={{width: '100%'}}
                placeholder={'Filter by test'}
                onChange={handleChange}
                options={productsList}
            />
        </Col>

        <FinalizedOrdersTable
            style={{padding: '0 10px'}}
            sticky={true}
            size={'small'}
            columns={columns}
            dataSource={kits}
            pagination={pagination}
            scroll={false}
        />

    </Row>;
}

export default PatientDetails;