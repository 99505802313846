import React from 'react';
import {CloseOutlined} from "@ant-design/icons";
import {Button, Col, Divider, Drawer, Row, Typography} from "antd";
import {LOGO_EFUNC, VIT_LAB} from "../../../assets/images";
import VL_PRODUCT from "../../../assets/images/vl_product_img.png";
import VL_GREEN_CHECK from "../../../assets/images/vl_green_check.png";
import {NavLink} from "react-router-dom";
import styled from "styled-components";

// const disableDrawer = (open) => {
//     // fix bug on mobile devices with scroll
//     if (open) {
//         document.body.style.overflow = "hidden";
//     } else {
//         document.body.style.overflow = "auto";
//     }
// }


const StyledRow = styled(Row)`
    overflow-y: scroll;
    height: 100vh;

    ::-webkit-scrollbar {
        width: 1px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

const VitaminLabsPatientOrderDrawer = ({onDrawerCloseHandler, isDrawerOpened, selectedKIT}) => {

    return <Drawer
        destroyOnClose={true}
        size={'large'}
        placement={'right'}
        closeIcon={<CloseOutlined/>}
        width={window.innerWidth > 1200 ? '26%' : '92%'}
        closable={window.innerWidth <= 1200}
        onClose={onDrawerCloseHandler}
        open={isDrawerOpened}
    >
        <Row gutter={[12, 12]}>

            <Col span={'24'}>
                <Row justify={'space-between'}>
                    <Col span={12}>
                        <img src={LOGO_EFUNC} alt="Efunctional logo" style={{width: '45px'}}/>
                    </Col>

                    <Col span={12}>
                        <img src={VIT_LAB} alt="VitaminLab logo"
                             style={{width: '50%', float: 'right'}}/>
                    </Col>
                </Row>
            </Col>

            <Col span={'24'}>
                <Typography.Title
                    style={{
                        fontSize: 24,
                        fontWeight: 400,
                        textTransform: 'uppercase',
                        margin: '20px 0 30px 0',
                        fontFamily: 'Montserrat',
                        color: '#353c4e',
                        textAlign: 'center'
                    }}>
                    Your Personalized Formula
                </Typography.Title>
            </Col>

            <Divider style={{margin: "-10px 0 0px 0"}}/>

            <Col span={'24'}>
                <Row justify={'center'} gutter={[24]}>
                    <Col span={8}>
                        <img src={VL_PRODUCT} alt="VitaminLab product" style={{width: '100%'}}/>
                    </Col>

                    <Col span={16}>
                        <Typography.Paragraph style={{
                            fontSize: 14, fontFamily: 'Montserrat', color: '#353c4e', padding: '10px 0 0 0'
                        }}>
                            We’ve partnered with VitaminLab, a company founded by healthcare professionals, to bring you
                            truly personalized vitamins based on your micronutrient test results.
                        </Typography.Paragraph>
                    </Col>
                </Row>
            </Col>


            <Divider style={{margin: "0"}}/>

            <Col span={24}>
                <Typography.Title
                    style={{fontFamily: 'Montserrat', fontSize: 14, fontWeight: 400, textTransform: 'uppercase'}}>
                    <strong>the perks</strong>
                </Typography.Title>
            </Col>

            <Col span={24}>
                <Row>
                    <Col span={2}>
                        <img width={20} src={VL_GREEN_CHECK} alt="green_check"/>&nbsp;
                    </Col>

                    <Col span={22}>
                        <Typography.Paragraph
                            style={{fontFamily: 'Montserrat, sans-serif', margin: '0 0 5px 0', fontSize: 13}}>
                            All your nutrients, in one formula.
                        </Typography.Paragraph>
                    </Col>
                </Row>

                <Row>
                    <Col span={2}>
                        <img width={20} src={VL_GREEN_CHECK} alt="green_check"/>&nbsp;
                    </Col>

                    <Col span={22}>
                        <Typography.Paragraph
                            style={{fontFamily: 'Montserrat, sans-serif', margin: '0 0 5px 0', fontSize: 13}}>
                            Complimentary consults with VitaminLab's registered nutritionists.&nbsp;
                            <a style={{color: "#00BBBE", textDecoration: "underline"}}
                               href="https://calendly.com/d/y7v-g43-mpp/custom-formula-consultation-wp">
                                Book a consult here
                            </a>.
                        </Typography.Paragraph>
                    </Col>
                </Row>

                <Row>
                    <Col span={2}>
                        <img width={20} src={VL_GREEN_CHECK} alt="green_check"/>&nbsp;
                    </Col>

                    <Col span={22}>
                        <Typography.Paragraph
                            style={{fontFamily: 'Montserrat, sans-serif', margin: '0 0 5px 0', fontSize: 13}}>
                            Supports your unique goals and made to evolve as you do.
                        </Typography.Paragraph>
                    </Col>
                </Row>

                <Row>
                    <Col span={2}>
                        <img width={20} src={VL_GREEN_CHECK} alt="green_check"/>&nbsp;
                    </Col>

                    <Col span={22}>
                        <Typography.Paragraph
                            style={{fontFamily: 'Montserrat, sans-serif', margin: '0 0 5px 0', fontSize: 13}}>
                            Made in an NSF certified lab.
                        </Typography.Paragraph>
                    </Col>
                </Row>
            </Col>

            <Divider style={{margin: "0"}}/>

            <Col span={24}>
                <Typography.Title style={{fontSize: 14, fontWeight: 400, textTransform: 'uppercase', margin: "0"}}>
                    <strong>Your custom formula</strong>
                </Typography.Title>
            </Col>

            {/*<Col span={24} style={{background: "#E9EDF0", padding: 15, borderRadius: 10}}>*/}
            {/*    {selectedKIT?.new_vitamins_json && selectedKIT?.new_vitamins_json.map(vitamin => <Row>*/}
            {/*        <Col span={14} key={vitamin.id}>*/}
            {/*            <div style={{fontFamily: 'Montserrat', margin: '0 0 7px 0', fontSize: 13}}>*/}
            {/*                {vitamin.supplement}*/}
            {/*            </div>*/}
            {/*        </Col>*/}

            {/*        <Col span={10}>*/}
            {/*            <strong style={{fontFamily: 'Montserrat', fontSize: 13}}>*/}
            {/*                {vitamin.calc} {vitamin.frequency}*/}
            {/*            </strong>*/}
            {/*        </Col>*/}
            {/*    </Row>)}*/}
            {/*</Col>*/}

            <Col span={24} style={{background: "#E9EDF0", padding: 15, borderRadius: 10}}>
                {selectedKIT?.new_vitamins_json && selectedKIT?.pretty_vitamins_json.map(vitamin =>
                    <Row>
                        <Col span={20} key={vitamin.id}>
                            <div style={{fontFamily: 'Montserrat', margin: '0 0 7px 0', fontSize: 13}}>
                                {/*{vitamin.supplement}*/}
                                {vitamin.vl_name}
                            </div>
                        </Col>

                        <Col span={4}>
                            <strong style={{fontFamily: 'Montserrat', fontSize: 13}}>
                                {/*{vitamin.calc} {vitamin.frequency}*/}
                                {vitamin.formula_value}
                            </strong>
                        </Col>
                    </Row>
                )}
            </Col>


            <Col span={24} style={{padding: 15, borderRadius: 10, background: 'rgba(22,159,138,0.1)'}}>
                <Typography.Text style={{fontFamily: 'Montserrat', fontSize: 13}}>
                    If you already have a formula and wish to modify based on updated goals or test results, please
                    contact the VitaminLab team at&nbsp;
                    <a style={{color: "#00BBBE", textDecoration: "underline"}}
                       href="mailto:info@getvitaminlab.com">info@getvitaminlab.com</a>
                    &nbsp;or&nbsp;
                    <a style={{color: "#00BBBE", textDecoration: "underline"}}
                       href="tel:1-888-948-4360">1-888-948-4360</a>.
                </Typography.Text>
            </Col>

            <Col span={24} style={{background: "#F4ECDD", padding: 15, borderRadius: 10}}>
                <Typography.Text style={{fontFamily: 'Montserrat', fontSize: 13}}>
                    ☝️ Ordering a new subscription will not cancel an existing active subscription.
                </Typography.Text>
            </Col>

            <Divider style={{margin: "0 0 10px 0"}}/>

            <Col span={24}>
                {/*this is development URL*/}
                {/*<NavLink to={`https://efunctional.staging.getvitaminlab.com/custom/${selectedKIT?.vl_line_code}`}>*/}
                <NavLink to={`https://efunctional.getvitaminlab.com/custom/${selectedKIT?.vl_line_code}`}>
                    <Button
                        disabled={selectedKIT?.vl_line_code === ""}
                        type={'primary'} style={{width: '100%'}}>
                        Purchase Supplements
                    </Button>
                </NavLink>
            </Col>

        </Row>
    </Drawer>

}

export default VitaminLabsPatientOrderDrawer;