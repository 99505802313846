import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Form, Radio, Checkbox, Input, Button, Typography, Divider, Tag, Row, Col, message, Result} from 'antd';
import restAPI from "../../../../features/restAPI";

const Quiz = ({quizData, patientData, kitBarcode, authConfig}) => {
        const [form] = Form.useForm();

        const navigate = useNavigate();

        const [agreedWithPolicy, setAgreedWithPolicy] = useState(false);
        const [isSubmit, setIsSubmit] = useState(false);

        const [resultData, setResultData] = useState({});

        const question5 = quizData.find(q => q.id === 5);

        const [selectedOptions, setSelectedOptions] = useState({
            5: question5
                ? question5.options.reduce((acc, option) => {
                    acc[option.value] = {checked: false, customInput: ''};
                    return acc;
                }, {})
                : {}
        });

        const mergeQuizDataWithResponses = (quizData, formData) => {
            // Initialize the structured data object.
            // Needed to store the responses in a structured format before sending to the server

            const structuredData = {
                anamnesis: {}
            };

            if (patientData.gender === 'Male') {
                quizData = quizData.filter(question => !question.onlyFemale);
            }

            // Loop through each question in the quizData to build the structure
            quizData.forEach((quiz) => {
                const questionKey = `q${quiz.id}`;
                const customInputKey = `${quiz.id}--userCustomInput`;

                // Initialize the response structure for the current question
                let response = {
                    value: formData[questionKey] !== undefined ? formData[questionKey] : null,
                    customInput: formData[customInputKey] || null
                };

                // Handle special cases for checkboxes with potential multiple custom inputs
                if (quiz.type === "checkbox" && Array.isArray(formData[questionKey])) {
                    // response.value = formData[questionKey]; // Array of checked values
                    delete response.value; // Remove the customInput field for checkboxes
                    response.customInputs = {}; // Object to store custom inputs for each option

                    // Collect custom inputs for each checkbox option if present
                    quiz.options.forEach((option) => {
                        const optionCustomInputKey = `${quiz.id}-${option.value}-customInput`;
                        if (formData[optionCustomInputKey]) {
                            // response.customInputs[option.value] = formData[optionCustomInputKey];
                            response.customInputs[option.value] = [{
                                value: option?.label || null,
                                customInput: formData[optionCustomInputKey]
                            }];
                        }
                    });
                }

                // Add the `label` from option. Label is better for - question with ID 6
                if (quiz.type === "radio" && quiz.onlyFemale) {
                    response.customInput = quiz.options.filter(option => option.value === response.value)[0]?.label;
                }

                // Add the response to the structured data
                structuredData.anamnesis[questionKey] = {
                    // id: quiz.id,
                    // title: quiz.title,
                    // subTitle: quiz.subTitle,
                    question: quiz.question,
                    type: quiz.type,
                    // options: quiz.options.map(option => ({
                    //     ...option,
                    //     customInput: response.customInputs ? response.customInputs[option.label] : null
                    // })),

                    // userCustomInput: quiz.userCustomInput,

                    // explanations: quiz.explanations,
                    onlyFemale: quiz.onlyFemale,
                    response: response
                };
            });

            return structuredData;
        }

        const onFinish = async (values) => {
            try {
                // console.log("form values :::", values)

                const submitData = mergeQuizDataWithResponses(quizData, values)

                setResultData({...submitData});

                const response = await restAPI.post(
                    `skin-care-kits/results/${kitBarcode}/anamnesis/`,
                    submitData,
                    authConfig
                );

                // console.log(response.data);

                message.success('Your questionnaire has been submitted successfully!');


                setIsSubmit(true);

            } catch (e) {
                console.log(e);
                message.error('Try to refresh the page and submit the form again. If the problem persists, please contact support.');
            }
        };


        const handleQuizItemChange = (e, questionId) => {

            console.log('e.target.value', e.target.value);

            // id 1. Custom behavior for the `other` option
            if (questionId === 1) {
                if (e.target.value === 'other') {
                    setSelectedOptions({
                        ...selectedOptions,
                        [questionId]: e.target.value,
                        [`${questionId}--userCustomInput`]: '',
                    });
                } else {
                    setSelectedOptions({
                        ...selectedOptions,
                        [questionId]: e.target.value,
                    });
                }
            }

            // id 2. Custom behavior for the `other` option
            if (questionId === 2) {
                if (e.target.value === 'yes') {
                    setSelectedOptions({
                        ...selectedOptions,
                        [questionId]: e.target.value,
                        [`${questionId}--userCustomInput`]: '',
                    });
                } else {
                    setSelectedOptions({
                        ...selectedOptions,
                        [questionId]: e.target.value,
                    });
                }
            }

            // default
            setSelectedOptions({
                ...selectedOptions,
                [questionId]: e.target.value,
            });
        };

        const handleCheckboxChange = (checkedValues, questionId, question) => {
            console.log('Checked Values:', checkedValues); // Log the values that are checked
            const updatedOptions = {...selectedOptions[questionId]};
            question.options.forEach(option => {
                updatedOptions[option.value].checked = checkedValues.includes(option.value);
                console.log(`Option: ${option.value}, Checked: ${updatedOptions[option.value].checked}`); // Check each option's checked status
            });
            setSelectedOptions({
                ...selectedOptions,
                [questionId]: updatedOptions
            });
            console.log('Updated Options for Question:', questionId, updatedOptions); // See the updated options state
        };


        // RENDER HERE
        // CASE 1: If the form is submitted successfully
        if (isSubmit) {
            return <Result
                status="success"
                title="Successfully submitted the questionnaire!"
                subTitle="Your responses have been recorded. Thank you for your time."
                extra={[
                    <Button type="primary" key="console" onClick={() => navigate('/')}>
                        Back to Home
                    </Button>,
                ]}
            />
        }

        // CASE 2: If the form is not submitted yet
        return <>
            <Form
                size={'small'}
                variant="filled"
                form={form}
                onFinish={onFinish}
            >
                {quizData
                    .filter(question => patientData.gender !== 'Male' || !question.onlyFemale)
                    .map((question, i) => (
                        <React.Fragment key={i}>
                            <Typography.Title
                                level={5}
                                style={{fontWeight: 400, textTransform: 'uppercase'}}
                                underline={true}
                            >
                                <Tag color={'#01a9ac'} bordered={false}>{i + 1}</Tag>
                                {question.question}
                            </Typography.Title>

                            <Typography.Paragraph style={{fontSize: 12}}>
                                {question.subTitle}
                            </Typography.Paragraph>


                            <Form.Item
                                key={question.id}
                                name={`q${question.id}`}
                                rules={[{required: question.id !== 5, message: 'Please select an option'}]}
                            >
                                {/* --- Radio Group START --- */}
                                {question.type === 'radio' && (
                                    <Radio.Group
                                        name={`q${question.id}`}
                                        onChange={(e) => handleQuizItemChange(e, question.id)}
                                    >
                                        {question.options.map((option) => (
                                            <Radio key={option.value} value={option.value}>
                                                {option.label}
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                )}
                                {/* --- Radio Group END --- */}


                                {/*{question.type === 'checkbox' && (*/}
                                {/*    <Checkbox.Group*/}
                                {/*        onChange={(checkedValues) =>*/}
                                {/*            handleCheckboxChange(checkedValues, `${question.id}`)*/}
                                {/*        }*/}
                                {/*    >*/}
                                {/*        <Row>*/}
                                {/*            {question.options.map((option) => (*/}

                                {/*                <Col span={24} key={option.value}>*/}
                                {/*                    <Checkbox value={option.value}>{option.label}</Checkbox>*/}
                                {/*                </Col>*/}

                                {/*            ))}*/}
                                {/*        </Row>*/}
                                {/*    </Checkbox.Group>*/}
                                {/*)}*/}

                                {question.type === 'checkbox' && (
                                    <Checkbox.Group
                                        key={`checkbox-group-${question.id}`}
                                        value={Object.keys(selectedOptions[question.id]).filter(optionValue => selectedOptions[question.id][optionValue].checked)}
                                        onChange={(checkedValues) => handleCheckboxChange(checkedValues, question.id, question)}
                                    >
                                        <Row>
                                            {question.options.map((option) => (
                                                <Col span={24} key={option.value}>
                                                    <Checkbox value={option.value}>
                                                        {option.label}
                                                    </Checkbox>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                )}

                                {/*<Checkbox.Group*/}
                                {/*    key={`checkbox-group-${new Date().getTime()}`}*/}
                                {/*    value={Object.keys(selectedOptions[question.id] || {})*/}
                                {/*        .filter(optionValue => selectedOptions[question.id][optionValue].checked)}*/}
                                {/*    onChange={(checkedValues) => handleCheckboxChange(checkedValues, question.id)}*/}
                                {/*>*/}
                                {/*    <Row>*/}
                                {/*        {question.options.map(option => (*/}
                                {/*            <Col span={24} key={option.value}>*/}
                                {/*                <Checkbox value={option.value}>{option.label}</Checkbox>*/}
                                {/*            </Col>*/}
                                {/*        ))}*/}
                                {/*    </Row>*/}
                                {/*</Checkbox.Group>*/}


                                {/*{question.type === 'checkbox' && (*/}
                                {/*    <Checkbox.Group*/}
                                {/*        key={forceRender}*/}
                                {/*        value={Object.keys(selectedOptions[question.id] || {}).filter(optionValue => selectedOptions[question.id][optionValue].checked)}*/}
                                {/*        onChange={checkedValues => handleCheckboxChange(checkedValues, question.id)}*/}
                                {/*    >*/}
                                {/*        <Row>*/}
                                {/*            {question.options.map(option => (*/}
                                {/*                <Col span={24} key={option.value}>*/}
                                {/*                    <Checkbox value={option.value}>{option.label}</Checkbox>*/}
                                {/*                </Col>*/}
                                {/*            ))}*/}
                                {/*        </Row>*/}
                                {/*    </Checkbox.Group>*/}
                                {/*)}*/}


                                {/* Render Form.Item with textarea for each checked option*/}
                                {Object.entries(selectedOptions[`${question.id}`] || {}).map(([optionValue, {
                                    checked,
                                    customInput
                                }]) => (
                                    checked && (
                                        <Form.Item
                                            key={`${question.id}-${optionValue}`}
                                            label={question.options.find(option => option.value === optionValue)?.label}
                                            name={`${question.id}-${optionValue}-customInput`}
                                            rules={[{required: true, message: 'Please specify your reaction'}]}
                                        >
                                            <Input.TextArea
                                                value={customInput}
                                                onChange={(e) =>
                                                    setSelectedOptions(prevOptions => ({
                                                        ...prevOptions,
                                                        [question.id]: {
                                                            ...prevOptions[question.id],
                                                            [optionValue]: {
                                                                ...prevOptions[question.id][optionValue],
                                                                customInput: e.target.value,
                                                            },
                                                        },
                                                    }))
                                                }
                                                placeholder="Please specify your reaction"
                                            />
                                        </Form.Item>
                                    )
                                ))}

                                {question.type === 'textarea' && <Input.TextArea/>}
                            </Form.Item>

                            {/*{question.userCustomInput &&*/}
                            {/*{selectedOptions[question.id] === 'other' && selectedOptions[question.id] === 'yes'*/}
                            {/*    ?*/}
                            {/*    <Form.Item*/}
                            {/*        name={`${question.id}--userCustomInput`}*/}
                            {/*        rules={[{*/}
                            {/*            required: selectedOptions[question.id] === 'other',*/}
                            {/*            message: 'Please specify'*/}
                            {/*        }]}*/}
                            {/*    >*/}
                            {/*        <Input*/}
                            {/*            // hidden={selectedOptions[question.id] !== 'other' && selectedOptions[question.id] !== 'yes'}*/}
                            {/*            placeholder="Please specify"/>*/}
                            {/*    </Form.Item>*/}
                            {/*    : null*/}
                            {/*}*/}

                            {(selectedOptions[question.id] === 'other' || selectedOptions[question.id] === 'yes') &&
                                <Form.Item
                                    name={`${question.id}--userCustomInput`}
                                    rules={[{
                                        required: (selectedOptions[question.id] === 'other' || selectedOptions[question.id] === 'yes'),
                                        message: 'Please specify'
                                    }]}
                                >
                                    <Input placeholder="Please specify"/>
                                </Form.Item>
                            }

                            <Divider/>

                        </React.Fragment>
                    ))
                }

                <Form.Item>
                    <Typography.Paragraph style={{fontSize: 12}}>
                        Your questionnaire data will be transferred to Essential Apothecary Pharmacy to create a
                        tailored
                        cream
                        formulation for you. Rest assured, all information is kept confidential and complies with HIPAA
                        standards.
                        Your privacy and security are our top priorities. If you have any questions or concerns about
                        how
                        your data
                        is handled, please don't hesitate to contact us. We're committed to ensuring the confidentiality
                        and
                        integrity of your information.
                    </Typography.Paragraph>

                    <Typography.Paragraph>
                        <Checkbox
                            checked={agreedWithPolicy}
                            onChange={(e) => setAgreedWithPolicy(e.target.checked)}
                        >
                            I agree to Essential Apothecary Privacy Policy and Terms of Use. All information must be
                            accurate
                            and
                            correspond to the patient under treatment.
                        </Checkbox>
                    </Typography.Paragraph>


                    <Button
                        disabled={!agreedWithPolicy}
                        type="primary" htmlType="submit" onClick={() => form.submit()}>
                        Submit
                    </Button>

                    <Divider/>


                </Form.Item>
            </Form>

            {/*<Col>*/}
            {/*    {JSON.stringify(form.getFieldsValue(), null, 2)}*/}
            {/*</Col>*/}

            {/*<Col>*/}
            {/*    <pre>*/}
            {/*        {JSON.stringify(selectedOptions, null, 2)}*/}
            {/*    </pre>*/}
            {/*</Col>*/}


            {/*<Col>*/}
            {/*    <pre>*/}
            {/*        {JSON.stringify(form.getFieldsValue(), null, 2)}*/}
            {/*    </pre>*/}
            {/*</Col>*/}

            {/*<Col>*/}
            {/*    <pre>*/}
            {/*        {JSON.stringify(resultData, null, 2)}*/}
            {/*    </pre>*/}
            {/*</Col>*/}
        </>;
    }
;


const quizData = [

    {
        id: 1,
        title: "",
        subTitle: "Choose one option",
        question: "What is your ethnicity?",
        type: "radio",
        options: [
            {value: "caucasian", label: "Caucasian"},
            {value: "africanAmerican", label: "African American"},
            {value: "hispanicOrLatino", label: "Hispanic or Latino"},
            {value: "asian", label: "Asian"},
            {value: "middleEastern", label: "Middle Eastern"},
            {value: "other", label: "Other"},
        ],
        userCustomInput: true,  // custom field only for the radio type with the `other` option
        explanations: null,
        onlyFemale: false,
    },

    {
        id: 2,
        title: "Medical History",
        question: "Do you have any medical conditions that your provider should know of?",
        subTitle: "Required",
        type: "radio",
        options: [
            {value: "yes", label: "Yes"},
            {value: "no", label: "No"},
        ],
        userCustomInput: true,
        explanations: "Please specify list",
        onlyFemale: false,
    },

    {
        id: 3,
        title: "Medications",
        question: "Do you currently take medications (oral or topical) or supplement(s)?",
        subTitle: "(ex: Niacinamide, Biotin, Birth control, Tranexamic acid, blood pressure or other. If yes please list)",
        type: "radio",
        options: [
            {value: "yes", label: "Yes"},
            {value: "no", label: "No"},
        ],
        userCustomInput: true,
        explanations: "Please specify list",
        onlyFemale: false,
    },

    {
        id: 4,
        title: "Medications",
        question: "Do you experience any of the following skin issues?",
        subTitle: "(ex: acne, rosacea, perioral dermatitis, sensitive skin, eczema, skin cancer, etc.)",
        type: "radio",
        options: [
            {value: "yes", label: "Yes"},
            {value: "no", label: "No"},
        ],
        userCustomInput: true,
        explanations: "Please specify list",
        onlyFemale: false,
    },

    {
        id: 5,
        title: "Medications",
        question: "Do you have any known skin or drug allergies?",
        // subTitle: "Please select your allergies and specify your reaction to each ingredient. Skip if none.",
        subTitle: <>
            Please select your allergies and specify your reaction to each ingredient.&nbsp;&nbsp;
            <Tag color={'#01a9ac'}>Skip if none.</Tag>
        </>,
        type: "checkbox",
        options: [
            {value: "tretinoin", label: "Tretinoin"},
            {value: "mashroomsKojicAcid", label: "Mashrooms/Kojic acid"},
            {value: "hydroquinone", label: "Hydroquinone"},
            {value: "penicillin", label: "Penicillin"},
            {value: "sulfur", label: "Sulfur/Sulfacetamide"},
            {value: "niacinamide", label: "Niacinamide"},
            {value: "vitaminC", label: "Vitamin C"},
            {value: "azelaicAcid", label: "Azelaic Acid"},
            {value: "tranexamicAcid", label: "Tranexamic Acid"},
            {value: "caffeine", label: "Caffeine"},
            {value: "greenTea", label: "Green Tea"},
            {value: "other", label: "Other"},
        ],
        userCustomInput: true,
        explanations: "Please select / list your allergies and specify your reaction to each ingredient.",
        // todo -  Please specify the medication name (the name that was selected under
        onlyFemale: false,
    },

    //Are you currently nursing or pregnant?

    {
        id: 6,
        title: "Medications",
        question: "Are you currently nursing or pregnant?",
        subTitle: "Required",
        type: "radio",
        options: [
            {value: "currentlyNotPregnantOrNursing", label: "I'm NOT currently nursing or pregnant"},
            {value: "pregnant", label: "I'm currently pregnant"},
            {value: "nursing", label: "I'm currently nursing"},
        ],
        userCustomInput: false,
        explanations: "Please specify list",

        // todo - if answer is yes, the window should come out:Congrats, mommy! Unfortunately though, we cannot provide
        //  you with treatment at this time. Since topical creams and lotions can be absorbed into the body in small amounts,
        //  it could potentially impact the development of the fetus. We would be happy to help you through your skincare
        //  journey once you're ready.
        onlyFemale: true,
    },

    {
        id: 7,
        title: "Medications",
        question: "Is there anything else your dermatologist should know before writing your prescription?",
        subTitle: "Required",
        type: "radio",
        options: [
            {value: "yes", label: "Yes"},
            {value: "no", label: "No"},
        ],
        userCustomInput: true,
        explanations: null,
        onlyFemale: false,
    },

    {
        id: 8,
        title: "Medications",
        question: "The Anti-Aging formulations Has a dermatologist seen you for these issues?",
        subTitle: "Required",
        type: "radio",
        options: [
            {value: "yes", label: "Yes"},
            {value: "no", label: "No"},
        ],
        userCustomInput: true,
        explanations: "The Anti-Aging formulations",
        onlyFemale: false,
    },


    // Is there anything else your dermatologist should know before writing your prescription?


    // todo - when all question are filled -> Review Your Essential Apothecary Visit
    //  last page is to review answers with edit option to each question. At the end this text : agree to Essential Apothecary Privacy Policy and Terms of Use. All information must be accurate and correspond to the patient under treatment.

    // todo - Parental Consent Are you at least 18 years old? Yes, I am over 18.
];


const SkinTestQuizComponent = ({patientData, kitBarcode, authConfig}) => {
    return <>

        {/*<Typography.Title level={5}>*/}
        {/*    Gender: {patientData?.gender}*/}
        {/*</Typography.Title>*/}

        <Quiz quizData={quizData} patientData={patientData} kitBarcode={kitBarcode} authConfig={authConfig}/>


    </>
}

export default SkinTestQuizComponent;