import React, {useState, useEffect, useRef} from "react";
import {useReactToPrint} from 'react-to-print';
import styled from 'styled-components';
import {EyeOutlined, PrinterOutlined} from '@ant-design/icons';
import {Space, Typography, Modal, Button, Row, Col, Spin, Alert} from "antd";

import HEADER_FOOD from '../../../assets/images/reports/food_alergy_resp/header_food.png';
import FA_ASSET_3 from '../../../assets/images/reports/food_alergy_resp/Asset_3.png';
import FA_ASSET_5 from '../../../assets/images/reports/food_alergy_resp/Asset_5.png';
import FA_ASSET_4 from '../../../assets/images/reports/food_alergy_resp/Asset_4.png';
import FA_ASSET_7 from '../../../assets/images/reports/food_alergy_resp/Asset_7.png';
import FA_ASSET_6 from '../../../assets/images/reports/food_alergy_resp/Asset_6.png';
import FA_ASSET_8 from '../../../assets/images/reports/food_alergy_resp/Asset_8.png';
import FOOTER_LOGO from '../../../assets/images/reports/food_alergy_resp/logo.png';

import HEADER_REP_FOOD from '../../../assets/images/reports/food_alergy_resp/header_rep.png';


import GREEN from '../../../assets/images/reports/food_alergy_resp/green.png';
import ORANGE from '../../../assets/images/reports/food_alergy_resp/orange.png';
import YELLOW from '../../../assets/images/reports/food_alergy_resp/yellow.png';
import RED from '../../../assets/images/reports/food_alergy_resp/red.png';

import GLUTEN from '../../../assets/images/reports/food_alergy_resp/gluten.png';
import GLUTEN_FREE from '../../../assets/images/reports/food_alergy_resp/gluten_free.png';
import LEGUMES from '../../../assets/images/reports/food_alergy_resp/legumes.png';
import NUTS from '../../../assets/images/reports/food_alergy_resp/nuts.png';
import FRUITS from '../../../assets/images/reports/food_alergy_resp/fruits.png';
import VEGETABLE from '../../../assets/images/reports/food_alergy_resp/vegetables.png';
import DAIRY from '../../../assets/images/reports/food_alergy_resp/dairy.png';
import MEAT from '../../../assets/images/reports/food_alergy_resp/meat.png';
import FISH from '../../../assets/images/reports/food_alergy_resp/fish.png';
import HERBS from '../../../assets/images/reports/food_alergy_resp/herbs.png';
import MISC from '../../../assets/images/reports/food_alergy_resp/misc.png';

// RESP
import HEADER_RESP_FOOD from '../../../assets/images/reports/food_alergy_resp/header_resp.png';
import POLLEN from '../../../assets/images/reports/food_alergy_resp/pollen.png';
import PET from '../../../assets/images/reports/food_alergy_resp/pet.png';
import DUST from '../../../assets/images/reports/food_alergy_resp/dust.png';
import MOLD from '../../../assets/images/reports/food_alergy_resp/mold.png';


const dataSet = {
    barcode: 'EF00001-RA01',
    results: [
        {code: 't70', description: 'Mulberry Tree', result: '0'},
        {code: 'w100', description: 'Sorrel', result: '0'},
        {code: 'g8', description: 'Meadow Grass', result: '0'},
        {code: 'w1', description: 'Common Ragweed', result: '0'},
        {code: 'w9', description: 'English Plantain', result: '0'},
        {code: 'm6', description: 'Alternaria alternata', result: '0'},
        {code: 'w10', description: 'Goosefoot', result: '0'},
        {code: 'd1', description: 'Dermatophagoides Pteronyssinus', result: '0'},
        {code: 't14', description: 'Poplar', result: '0'},
        {code: 't12', description: 'Willow', result: '0'},
        {code: 't7', description: 'Oak', result: '0'},
        {code: 'm2', description: 'Cladosporium herbarum', result: '0'},
        {code: 'e5', description: 'Dog', result: '0'},
        {code: 'g1', description: 'Sweet Vernal Grass', result: '0'},
        {code: 'm3', description: 'Aspergillus fumigatus', result: '0'},
        {code: 'w103', description: 'Nettle', result: '1'},
        {code: 't5', description: 'Beech', result: '0'},
        {code: 't19', description: 'Acacia', result: '0'},
        {code: 'w11', description: 'Russian Thistle', result: '0'},
        {code: 't10', description: 'Walnut Tree', result: '0'},
        {code: 't11', description: 'Plane Tree', result: '4'},
        {code: 'e72', description: 'Mouse urine proteins', result: '0'},
        {code: 'g5', description: 'Rye Grass', result: '0'},
        {code: 'd2', description: 'Dermatophagoides Farinae', result: '0'},
        {code: 'g3', description: 'Orchard Grass', result: '3'},
        {code: 'm1', description: 'Penicillium Notatum', result: '0'},
        {code: 't3', description: 'Birch', result: '0'},
        {code: 'g6', description: 'Timothy Grass', result: '2'},
        {code: 'i6', description: 'Cockroach German', result: '0'},
        {code: 't15', description: 'White Ash', result: '5'},
        {code: 'w43', description: 'Sagebrush', result: '0'},
        {code: 'w6', description: 'Mugwort', result: '0'},
        {code: 't9', description: 'Olive Tree', result: '0'},
        {code: 'w14', description: 'Rough Pigweed', result: '0'},
        {code: 'g10', description: 'Johnson Grass', result: '0'},
        {code: 'g2', description: 'Bermuda Grass', result: '0'},
        {code: 'e1', description: 'Cat', result: '0'},
        {code: 't1', description: 'Box Elder', result: '0'},
        {code: 't16', description: 'White Pine', result: '0'},
        {code: 't8', description: 'Elm', result: '6'}
    ]
}

const dataSetFoodAllergy = {
    barcode: 'EF00001-FA01',
    results: [
        {code: 'f13', description: 'Peanut', result: '5'},
        {code: 'f1', description: 'Egg White', result: '1'},
        {code: 'f26', description: 'Pork', result: '0'},
        {code: 'f45', description: "Baker's Yeast", result: '0'},
        {code: 'f12', description: 'Pea', result: '0'},
        {code: 'f4', description: 'Wheat Flour', result: '0'},
        {code: 'f14', description: 'Soybean', result: '0'},
        {code: 'f83', description: 'Chicken', result: '0'},
        {code: 'f24', description: 'Shrimp/Prawn', result: '0'},
        {code: 'f10', description: 'Sesame', result: '0'},
        {code: 'f2', description: "Cow's Milk", result: '2'},
        {code: 'f23', description: 'Crab', result: '0'},
        {code: 'f84', description: 'Kiwi', result: '0'},
        {
            code: 'f78',
            description: "nBos d8- Casein(cow'smilk)",
            result: '0'
        },
        {code: 'f25', description: 'Tomato', result: '0'},
        {code: 'f40', description: 'Tuna', result: '0'},
        {code: 'f92', description: 'Banana', result: '0'},
        {code: 'f27', description: 'Beef', result: '0'},
        {code: 'f95', description: 'Peach', result: '0'},
        {code: 'f20', description: 'Almond', result: '0'},
        {code: 'f85', description: 'Celery', result: '0'},
        {code: 'f49', description: 'Apple', result: '0'},
        {code: 'f9', description: 'Rice', result: '0'},
        {code: 'f75', description: 'Egg Yolk', result: '4'},
        {code: 'f96', description: 'Avocado', result: '0'},
        {code: 'f8', description: 'Maize Flour', result: '0'},
        {code: 'f89', description: 'Mustard', result: '0'},
        {code: 'f158', description: 'Cashew Nut', result: '0'},
        {code: 'f218', description: 'Goats Milk', result: '3'},
        {code: 'f48', description: 'Onion', result: '0'},
        {code: 'f47', description: 'Garlic', result: '0'},
        {code: 'f3', description: 'Codfish', result: '0'}
    ]
}


const PrintingDiv = styled.div`
    @media print {
        & {
            padding: 0;
            margin: 0;
        }
    }
`;

function ResultImage({result}) {
    const parsedResult = parseInt(result, 10);
    let imageSrc = null;

    if (result === '0' || result === '1') {
        imageSrc = GREEN;
    } else if (parsedResult >= 2 && parsedResult <= 3) {
        imageSrc = YELLOW;
    } else if (parsedResult >= 4 && parsedResult <= 5) {
        imageSrc = ORANGE;
    } else if (parsedResult >= 6) {
        imageSrc = RED;
    }

    return imageSrc ? <img src={imageSrc} className="reaction" alt="Result indicator"/> : null;
}


// Component for rendering a single category table
const CategoryTable = ({categoryName, items, categoryImg}) =>
    (
        <div className="results_block">
            <table>
                <tbody>
                <tr>
                    <th className="res_icon"><img src={categoryImg} alt="Category icon"/></th>
                    <th className="res_name">{categoryName}</th>
                    <th colSpan="2" className="right">Intensity of reaction</th>
                </tr>

                {items.length > 0 && items.map((item, index) => (
                    <tr key={`${categoryName}-${index}`}> {/* Use a combination of category name and index for a more stable key */}
                        <td colSpan="2" className="left">{item.description}</td>

                        {/* ------ Commented for now, because we have not an enough data for displaying it ------ */}
                        {/*<td className="right">={item.result}</td>*/}
                        {/* --------------------------------------------------------------------------------------*/}

                        <td className="right">
                            <ResultImage result={item.result}/>
                        </td>
                    </tr>
                ))}

                </tbody>
            </table>
        </div>
    );

// Main component
const DisplayResults = ({categorizedData, food = false, rasp = false}) => {

    if (rasp) {

        // Split the data into the first category and the remaining categories. RASP REPORT
        const [firstCategoryEntry, ...otherCategoriesEntries] = Object.entries(categorizedData);

        return <div className="results_wrapper">

            <div className="results_col">
                {/* Render the first category */}
                {firstCategoryEntry &&
                    <CategoryTable
                        categoryName={firstCategoryEntry[0]}
                        items={firstCategoryEntry[1].items}
                        categoryImg={firstCategoryEntry[1].categoryImg}
                    />}
            </div>
            <div className="results_col">
                {/* Render the rest of the categories */}
                {otherCategoriesEntries.map(([categoryName, items]) => (
                    <CategoryTable
                        key={categoryName}
                        categoryName={categoryName}
                        items={items?.items}
                        categoryImg={items?.categoryImg}
                    />

                ))}
            </div>
        </div>

    }


    if (food) {
        // FOOD ALLERGY REPORT
        // Split the data into the first five categories and the remaining categories
        const [firstEntry, secondEntry, thirdEntry, fourthEntry, fifthEntry, ...otherCategoriesEntries] = Object.entries(categorizedData);

        // Array to hold the first five entries
        const firstFiveEntries = [firstEntry, secondEntry, thirdEntry, fourthEntry, fifthEntry];


        // return JSON.stringify(categorizedData);


        return <div className="results_wrapper">

            <div className="results_col">
                {/* Render the first category */}
                {firstFiveEntries.map(([categoryName, items]) => (
                    <div className="results_col" key={categoryName}>
                        <CategoryTable
                            categoryName={categoryName}
                            items={items.items}
                            categoryImg={items.categoryImg}
                        />
                    </div>
                ))}
            </div>
            <div className="results_col">
                {/* Render the rest of the categories */}
                {otherCategoriesEntries.map(([categoryName, items]) => (
                    <CategoryTable
                        key={categoryName}
                        categoryName={categoryName}
                        items={items?.items}
                        categoryImg={items?.categoryImg}
                    />

                ))}
            </div>
        </div>
    }
};

const ReportBody = styled.div`

    @media print {
        .wrapper {
            padding: 120px;
            margin: 0;
        }
    }

    .wrapper {
        background-color: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }

    .report {
        position: relative;
        width: 612px;
        height: 792px;
    }

    .header img {
        width: 100%;
    }

    .patient_info_wrapper {
        width: 512px;
        margin: auto;
    }

    .patient_info {
        padding-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: "Arial-Regular", Helvetica;
        font-weight: 400;
        color: #000000;
        //font-size: 10px;
        font-size: .8em;
    }

    .patient_info_block {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 30%;
        margin-right: 5px;
    }

    .icon {
        width: 25px;
        height: 25px;
        object-fit: cover;
    }

    .text_block {
        padding-left: 10px;
        line-height: 14px;
        overflow-wrap: break-word;
    }

    .text_block p {
        margin: 0;
    }

    .info {
        margin: auto;
        width: 512px;
        padding-top: 15px;
        font-family: "Arial-Regular", Helvetica;
        font-weight: 400;
        color: #000000;
        //font-size: 10px;
        font-size: .8em;
    }

    .info p {
        margin: 0;
    }

    .info span {
        font-family: "Arial-Bold", Helvetica;
        font-weight: 700;
        color: #1ea18b;
    }

    .footer {
        position: absolute;
        bottom: 25px;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .footer img {
        width: 145px;
    }

    .footer_info {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 511px;
        height: 25px;
        border: 2px solid #b4b4b4;
        background-color: #ffffff;
    }

    .footer a {
        font-family: "Arial-Regular", Helvetica;
        //line-height: normal;
        line-height: 10px;
        color: #000000;
        font-weight: 400;
        //font-size: 8px;
        font-size: .6em;
    }

    .footer_info img {
        width: 43px;
        height: 10px;
        margin-left: -15px;
    }

    .title {
        display: block;
        margin: 15px auto 0 auto;
        width: 511px;
        padding: 7px 0 7px 0;
        background-color: #1EA18B;
    }

    .title p {
        margin: 0;
        font-family: "Arial-Bold", Helvetica;
        font-weight: 700;
        color: #ffffff;
        //font-size: 16px;
        font-size: 1.1em;
        text-align: center;
        letter-spacing: 0.8px;
        text-transform: uppercase;
    }

    .results_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        max-width: 512px;
        margin: auto;
    }

    .results_col {
        display: flex;
        flex-direction: column;
    }

    .results_block {
        padding-top: 15px;
    }

    .results_block table {
        width: 237px;
        border: 2px solid #b4b4b4;
        border-collapse: collapse;
        font-family: "Arial-Regular", Helvetica;
        line-height: normal;
        vertical-align: middle;
    }

    .results_block table th {
        background-color: #b4b4b4;
        color: #ffffff;
        font-weight: 600;
        //font-size: 8px;
        font-size: .7em;
        padding: 3px 0;

        line-height: normal;
        overflow-wrap: break-word;
    }

    .results_block table th img {
        width: 19px;
    }

    .results_block table tr {
        background-color: #ffffff;
        height: 14px;
    }

    .results_block table tr:nth-child(odd) {
        background-color: #F5F4F1;
    }

    .results_block table td {
        color: #000000;
        font-weight: 400;
        //font-size: 8px;
        font-size: .75em;
    }

    .results_block table td img {
        width: 43px;
    }

    .res_icon {
        width: 12%;
        text-align: left;
        padding-left: 3px;
    }

    .res_name {
        width: 45%;
        text-align: left;
    }

    .right {
        text-align: right;
        padding-right: 3px;
    }

    .left {
        text-align: left;
        padding-left: 3px;
    }
`

const PrintableComponent = React.forwardRef(({printHeaderVisible, columns, title}, ref) => {

    // const {firstName, lastName} = data;

    const firstName = 'John';
    const lastName = 'Doe';
    const dob = '01/7/1976';
    const patientId = '2401000096';
    const reportDate = '01/8/2024 12:10:41 PM';


    // dataSetFoodAllergy


    const [data, setData] = useState(dataSetFoodAllergy);

    const categorizedData = categorizeResults(data);


    function categorizeResults(data) {
        // Define categories with identifying keywords, all in lowercase
        const categories = {
            Gluten: ['wheat',],
            'Gluten free': ['maize', 'rice',],
            Legumes: ['pea', 'soybean',],
            Nuts: ['almond', 'cashew', 'peanut', 'sesame'],
            Fruits: ['apple', 'avocado', 'banana', 'kiwi', 'peach',],
            Vegetables: ['celery', 'onion', 'tomato',],
            Dairy: ['casein', "cow's", 'egg', 'yolk', "goat's"],
            Meat: ['beef', 'chicken', 'pork',],
            Fish: ['codfish', 'crab', 'shrimp/prawn', 'tuna'],
            Herbs: ['garlic', 'mustard',],
            Miscellaneous: ["baker's",]
        };

        // Define category images
        const categoryImages = {
            Gluten: GLUTEN,
            'Gluten free': GLUTEN_FREE,
            Legumes: LEGUMES,
            Nuts: NUTS,
            Fruits: FRUITS,
            Vegetables: VEGETABLE,
            Dairy: DAIRY,
            Meat: MEAT,
            Fish: FISH,
            Herbs: HERBS,
            Miscellaneous: MISC,

            // Others: null
        };

        // Object to hold categorized results, including an "Others" category
        const categorizedResults = {
            Gluten: {items: [], categoryImg: categoryImages.Gluten},
            'Gluten free': {items: [], categoryImg: categoryImages['Gluten free']},
            Legumes: {items: [], categoryImg: categoryImages.Legumes},
            Nuts: {items: [], categoryImg: categoryImages.Nuts},
            Fruits: {items: [], categoryImg: categoryImages.Fruits},
            Vegetables: {items: [], categoryImg: categoryImages.Vegetables},
            Dairy: {items: [], categoryImg: categoryImages.Dairy},
            Meat: {items: [], categoryImg: categoryImages.Meat},
            Fish: {items: [], categoryImg: categoryImages.Fish},
            Herbs: {items: [], categoryImg: categoryImages.Herbs},
            Miscellaneous: {items: [], categoryImg: categoryImages.Miscellaneous},
            // Others: {items: [], categoryImg: categoryImages.Others} // Initialize the Others category
        };

        // Iterate over each result
        data.results.forEach((item) => {
            let matched = false;
            const descriptionLower = item.description.toLowerCase();

            // Check each category for a keyword match
            for (const [category, keywords] of Object.entries(categories)) {
                if (keywords.some(keyword => descriptionLower.includes(keyword))) {
                    categorizedResults[category].items.push(item);
                    matched = true;
                    break; // Stop searching once a match is found
                }
            }

            // If no category match was found, add it to the 'Others' category
            if (!matched) {
                categorizedResults?.Others?.items.push(item);
            }
        });

        return categorizedResults;
    }


    return (
        <PrintingDiv ref={ref}>
            {printHeaderVisible && <Typography.Title level={2}>{title}</Typography.Title>}

            {/*{JSON.stringify(categorizedData)}*/}


            <ReportBody>
                <div className="wrapper">
                    <div className="report">
                        <div className="header">
                            <img src={HEADER_FOOD} alt={'header logo'}/>
                        </div>

                        <div className="patient_info_wrapper">
                            <div className="patient_info">

                                <div className="patient_info_block">
                                    <img className="icon" src={FA_ASSET_3} alt={'asset_3'}/>
                                    <div className="text_block">
                                        <p className="text">Patient name:</p>
                                        <p className="answer">{firstName} {lastName}</p>
                                    </div>
                                </div>

                                <div className="patient_info_block">
                                    <img className="icon" src={FA_ASSET_5}/>
                                    <div className="text_block">
                                        <p className="text">Date of birth:</p>
                                        <p className="answer">{dob}</p>
                                    </div>
                                </div>
                                <div className="patient_info_block">
                                    <img className="icon" src={FA_ASSET_4}/>
                                    <div className="text_block">
                                        <p className="text">Patient ID:</p>
                                        <p className="answer">{patientId}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="patient_info">
                                <div className="patient_info_block">
                                    <img className="icon" src={FA_ASSET_7}/>
                                    <div className="text_block">
                                        <p className="text">Laboratory:</p>
                                        <p className="answer">Empire City Labs</p>
                                    </div>
                                </div>
                                <div className="patient_info_block">
                                    <img className="icon" src={FA_ASSET_6}/>
                                    <div className="text_block">
                                        <p className="text">Date:</p>
                                        <p className="answer">{reportDate}</p>
                                    </div>
                                </div>
                                <div className="patient_info_block">
                                    <img className="icon" src={FA_ASSET_8}/>
                                    <div className="text_block">
                                        <p className="text">Tested foods:</p>
                                        <p className="answer">108</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="info">

                            <p>
                                Allergens are acquired either through the air (inhalation allergies) or by ingestion
                                (food allergies). Patients may develop a Type I hypersensitivity reaction resulting from
                                the production of specific lgE antibodies. lgE antibodies are short-term (acute)
                                antibodies that circulate in the blood and may be associated with an anaphylactic
                                hypersensitivity response. Symptoms of these immediate-type reactions occur shortly
                                after contact with an allergen and may include rash, edema, itching, runny nose and
                                difficulty breathing.
                            </p>

                            <br/>

                            <p>
                                The <span>Euroline</span> inhalation and food allergy tests are semi-quantitative tests
                                for the determination of inhalation-specific and food-specific IgE
                                antibodies. <span>Euroline</span> results are denoted by a color-coded scale from 0-6.
                            </p>

                            <br/>

                            <p>
                                This assay <strong>does not</strong> identify lgG antibodies which are long-term
                                antibodies and may be
                                associated with a delayed-onset (secondary) hypersensitivity response.
                            </p>

                            <br/>

                            <p>
                                This test has not been evaluated by the U.S. Food and Drug Administration and its
                                performance characteristics have been determined by Empire City Laboratories under the
                                Clinical Laboratory Improvement Amendments (CLIA).
                            </p>
                        </div>
                        <div className="footer">
                            <img src={FOOTER_LOGO}/>
                        </div>
                    </div>
                </div>

                <div class="wrapper">
                    <div class="report">

                        <div class="header">
                            <img src={HEADER_REP_FOOD}/>
                        </div>

                        <div class="title">
                            <p>your personal results in detail</p>
                        </div>

                        <div class="patient_info_wrapper">
                            <div class="patient_info">
                                <div class="patient_info_block">
                                    <img class="icon" src={FA_ASSET_3}/>
                                    <div class="text_block">
                                        <p class="text">Patient name:</p>
                                        <p class="answer">{firstName} {lastName}</p>
                                    </div>
                                </div>
                                <div class="patient_info_block">
                                    <img class="icon" src={FA_ASSET_4}/>
                                    <div class="text_block">
                                        <p class="text">Patient ID:</p>
                                        <p class="answer">{patientId}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <DisplayResults categorizedData={categorizedData} food={true}/>

                        {/*<div class="results_wrapper">*/}
                        {/*    <div class="results_col">*/}
                        {/*        <div class="results_block">*/}
                        {/*            <table>*/}
                        {/*                <tr>*/}
                        {/*                    <th class="res_icon"><img src={GLUTEN}/></th>*/}
                        {/*                    <th class="res_name">Gluten containing cereals</th>*/}
                        {/*                    <th colspan="2" class="right">Intensity of reaction</th>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Wheat flour</td>*/}
                        {/*                    <td class="right">=7.5</td>*/}
                        {/*                    <td class="right"><img class="green" src={GREEN}/></td>*/}
                        {/*                </tr>*/}
                        {/*            </table>*/}
                        {/*        </div>*/}
                        {/*        <div class="results_block">*/}
                        {/*            <table>*/}
                        {/*                <tr>*/}
                        {/*                    <th class="res_icon"><img src={GLUTEN_FREE}/></th>*/}
                        {/*                    <th class="res_name">Gluten free cereals & alternative food</th>*/}
                        {/*                    <th colspan="2" class="right">Intensity of reaction</th>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Maize flour</td>*/}
                        {/*                    <td class="right">=7.5</td>*/}
                        {/*                    <td class="right"><img class="green" src={GREEN}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Rice</td>*/}
                        {/*                    <td class="right">28</td>*/}
                        {/*                    <td class="right"><img class="green" src={ORANGE}/></td>*/}
                        {/*                </tr>*/}
                        {/*            </table>*/}
                        {/*        </div>*/}
                        {/*        <div class="results_block">*/}
                        {/*            <table>*/}
                        {/*                <tr>*/}
                        {/*                    <th class="res_icon"><img src={LEGUMES}/></th>*/}
                        {/*                    <th class="res_name">Legumes</th>*/}
                        {/*                    <th colspan="2" class="right">Intensity of reaction</th>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Pea</td>*/}
                        {/*                    <td class="right">=7.5</td>*/}
                        {/*                    <td class="right"><img class="green" src={GREEN}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Soybean</td>*/}
                        {/*                    <td class="right">28</td>*/}
                        {/*                    <td class="right"><img class="green" src={ORANGE}/></td>*/}
                        {/*                </tr>*/}
                        {/*            </table>*/}
                        {/*        </div>*/}
                        {/*        <div class="results_block">*/}
                        {/*            <table>*/}
                        {/*                <tr>*/}
                        {/*                    <th class="res_icon"><img src={NUTS}/></th>*/}
                        {/*                    <th class="res_name">Nuts & seeds</th>*/}
                        {/*                    <th colspan="2" class="right">Intensity of reaction</th>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Almond</td>*/}
                        {/*                    <td class="right">=7.5</td>*/}
                        {/*                    <td class="right"><img class="green" src={GREEN}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Cashew Nut</td>*/}
                        {/*                    <td class="right">28</td>*/}
                        {/*                    <td class="right"><img class="green" src={YELLOW}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Peanut</td>*/}
                        {/*                    <td class="right">=7.5</td>*/}
                        {/*                    <td class="right"><img class="green" src={GREEN}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Sesame Nut</td>*/}
                        {/*                    <td class="right">28</td>*/}
                        {/*                    <td class="right"><img class="green" src={YELLOW}/></td>*/}
                        {/*                </tr>*/}
                        {/*            </table>*/}
                        {/*        </div>*/}
                        {/*        <div class="results_block">*/}
                        {/*            <table>*/}
                        {/*                <tr>*/}
                        {/*                    <th class="res_icon"><img src={FRUITS}/></th>*/}
                        {/*                    <th class="res_name">Fruits</th>*/}
                        {/*                    <th colspan="2" class="right">Intensity of reaction</th>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Apple</td>*/}
                        {/*                    <td class="right">=7.5</td>*/}
                        {/*                    <td class="right"><img class="green" src={GREEN}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Avocado</td>*/}
                        {/*                    <td class="right">28</td>*/}
                        {/*                    <td class="right"><img class="green" src={YELLOW}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Banana</td>*/}
                        {/*                    <td class="right">=7.5</td>*/}
                        {/*                    <td class="right"><img class="green" src={GREEN}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Kiwi</td>*/}
                        {/*                    <td class="right">28</td>*/}
                        {/*                    <td class="right"><img class="green" src={RED}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Peach</td>*/}
                        {/*                    <td class="right">28</td>*/}
                        {/*                    <td class="right"><img class="green" src={RED}/></td>*/}
                        {/*                </tr>*/}
                        {/*            </table>*/}
                        {/*        </div>*/}
                        {/*        <div class="results_block">*/}
                        {/*            <table>*/}
                        {/*                <tr>*/}
                        {/*                    <th class="res_icon"><img src={VEGETABLE}/></th>*/}
                        {/*                    <th class="res_name">Vegetables</th>*/}
                        {/*                    <th colspan="2" class="right">Intensity of reaction</th>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Celery</td>*/}
                        {/*                    <td class="right">=7.5</td>*/}
                        {/*                    <td class="right"><img class="green" src={GREEN}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Onion</td>*/}
                        {/*                    <td class="right">28</td>*/}
                        {/*                    <td class="right"><img class="green" src={ORANGE}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Tomato</td>*/}
                        {/*                    <td class="right">=7.5</td>*/}
                        {/*                    <td class="right"><img class="green" src={GREEN}/></td>*/}
                        {/*                </tr>*/}
                        {/*            </table>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div class="results_col">*/}
                        {/*        <div class="results_block">*/}
                        {/*            <table>*/}
                        {/*                <tr>*/}
                        {/*                    <th class="res_icon"><img src={DAIRY}/></th>*/}
                        {/*                    <th class="res_name">Dairy & egg</th>*/}
                        {/*                    <th colspan="2" class="right">Intensity of reaction</th>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Casein</td>*/}
                        {/*                    <td class="right">=7.5</td>*/}
                        {/*                    <td class="right"><img class="green" src={GREEN}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Cow's milk</td>*/}
                        {/*                    <td class="right">28</td>*/}
                        {/*                    <td class="right"><img class="green" src={YELLOW}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Egg white</td>*/}
                        {/*                    <td class="right">=7.5</td>*/}
                        {/*                    <td class="right"><img class="green" src={GREEN}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Egg yolk</td>*/}
                        {/*                    <td class="right">28</td>*/}
                        {/*                    <td class="right"><img class="green" src={RED}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Goat's milk</td>*/}
                        {/*                    <td class="right">28</td>*/}
                        {/*                    <td class="right"><img class="green" src={RED}/></td>*/}
                        {/*                </tr>*/}
                        {/*            </table>*/}
                        {/*        </div>*/}
                        {/*        <div class="results_block">*/}
                        {/*            <table>*/}
                        {/*                <tr>*/}
                        {/*                    <th class="res_icon"><img src={MEAT}/></th>*/}
                        {/*                    <th class="res_name">Meat</th>*/}
                        {/*                    <th colspan="2" class="right">Intensity of reaction</th>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Beef</td>*/}
                        {/*                    <td class="right">=7.5</td>*/}
                        {/*                    <td class="right"><img class="green" src={GREEN}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Chicken</td>*/}
                        {/*                    <td class="right">28</td>*/}
                        {/*                    <td class="right"><img class="green" src={YELLOW}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Pork</td>*/}
                        {/*                    <td class="right">=7.5</td>*/}
                        {/*                    <td class="right"><img class="green" src={GREEN}/></td>*/}
                        {/*                </tr>*/}
                        {/*            </table>*/}
                        {/*        </div>*/}
                        {/*        <div class="results_block">*/}
                        {/*            <table>*/}
                        {/*                <tr>*/}
                        {/*                    <th class="res_icon"><img src={FISH}/></th>*/}
                        {/*                    <th class="res_name">Fish & seafood</th>*/}
                        {/*                    <th colspan="2" class="right">Intensity of reaction</th>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Codfish</td>*/}
                        {/*                    <td class="right">=7.5</td>*/}
                        {/*                    <td class="right"><img class="green" src={GREEN}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Crab</td>*/}
                        {/*                    <td class="right">28</td>*/}
                        {/*                    <td class="right"><img class="green" src={YELLOW}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Shrimp/prawn</td>*/}
                        {/*                    <td class="right">=7.5</td>*/}
                        {/*                    <td class="right"><img class="green" src={GREEN}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Tuna</td>*/}
                        {/*                    <td class="right">28</td>*/}
                        {/*                    <td class="right"><img class="green" src={RED}/></td>*/}
                        {/*                </tr>*/}
                        {/*            </table>*/}
                        {/*        </div>*/}
                        {/*        <div class="results_block">*/}
                        {/*            <table>*/}
                        {/*                <tr>*/}
                        {/*                    <th class="res_icon"><img src={HERBS}/></th>*/}
                        {/*                    <th class="res_name">Herbs & spices</th>*/}
                        {/*                    <th colspan="2" class="right">Intensity of reaction</th>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Garlic</td>*/}
                        {/*                    <td class="right">=7.5</td>*/}
                        {/*                    <td class="right"><img class="green" src={GREEN}/></td>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Mustard</td>*/}
                        {/*                    <td class="right">28</td>*/}
                        {/*                    <td class="right"><img class="green" src={YELLOW}/></td>*/}
                        {/*                </tr>*/}
                        {/*            </table>*/}
                        {/*        </div>*/}
                        {/*        <div class="results_block">*/}
                        {/*            <table>*/}
                        {/*                <tr>*/}
                        {/*                    <th class="res_icon"><img src={MISC}/></th>*/}
                        {/*                    <th class="res_name">Miscellaneous</th>*/}
                        {/*                    <th colspan="2" class="right">Intensity of reaction</th>*/}
                        {/*                </tr>*/}
                        {/*                <tr>*/}
                        {/*                    <td colspan="2" class="left">Baker's yest</td>*/}
                        {/*                    <td class="right">=7.5</td>*/}
                        {/*                    <td class="right"><img src={GREEN} src={RED}/></td>*/}
                        {/*                </tr>*/}
                        {/*            </table>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="footer">
                            <div className="footer_info">
                                <a>Interpretation of results:</a>
                                <a>Undetectable</a><img src={GREEN} alt={'image'}/>
                                <a>Moderate</a><img src={YELLOW} alt={'image'}/>
                                <a>High</a><img src={ORANGE} alt={'image'}/>
                                <a>Very high</a><img src={RED} alt={'image'}/>
                            </div>
                        </div>

                    </div>
                </div>
            </ReportBody>
        </PrintingDiv>
    );
});


const PrintableComponent2 = React.forwardRef(({printHeaderVisible, columns, title}, ref) => {
    /* Respiratory Report and settings  */

    const [data, setData] = useState(dataSet);

    const categorizedData = categorizeResults(data);

    function categorizeResults(data) {
        // Define categories with identifying keywords, all in lowercase
        const categories = {
            Pollen: [
                'tree', 'vernal', 'plantain', 'nettle', 'ragweed', 'thistle', 'bermuda', 'orchard', 'rye',
                'timothy', 'meadow', 'sweet', 'poplar', 'birch', 'oak', 'elm', 'ash', 'acacia', 'plane',
                'willow', 'box elder', 'white pine', 'mulberry', 'sorrel', 'goosefoot', 'mugwort', 'olive',
                'sagebrush', 'beech', 'pigweed', 'johnson'
            ],
            'Pet Dander': ['dog', 'cat', 'mouse'],
            Dust: ['pteronyssinus', 'cockroach', 'farinae',],
            Mold: ['alternaria', 'cladosporium', 'aspergillus', 'penicillium']
        };

        // Define category images
        const categoryImages = {
            Pollen: POLLEN,
            'Pet Dander': PET,
            Dust: DUST,
            Mold: MOLD,
            // Others: null
        };

        // Object to hold categorized results, including an "Others" category
        const categorizedResults = {
            Pollen: {items: [], categoryImg: categoryImages.Pollen},
            'Pet Dander': {items: [], categoryImg: categoryImages['Pet Dander']},
            Dust: {items: [], categoryImg: categoryImages.Dust},
            Mold: {items: [], categoryImg: categoryImages.Mold},
            // Others: {items: [], categoryImg: categoryImages.Others} // Initialize the Others category
        };

        // Iterate over each result
        data.results.forEach((item) => {
            let matched = false;
            const descriptionLower = item.description.toLowerCase();

            // Check each category for a keyword match
            for (const [category, keywords] of Object.entries(categories)) {
                if (keywords.some(keyword => descriptionLower.includes(keyword))) {
                    categorizedResults[category].items.push(item);
                    matched = true;
                    break; // Stop searching once a match is found
                }
            }

            // If no category match was found, add it to the 'Others' category
            if (!matched) {
                categorizedResults.Others.items.push(item);
            }
        });

        return categorizedResults;
    }


    // Assuming your dataset is stored in a variable named `data`
    // const data = { ... }; // Your dataset here

    // Call the function and store the result
    // const categorizedData = categorizeResults(data);

    return (
        <PrintingDiv ref={ref}>
            {printHeaderVisible && <Typography.Title level={2}>{title}</Typography.Title>}

            <ReportBody>
                <div className="wrapper">
                    <div className="report">
                        <div className="header">
                            <img src={HEADER_RESP_FOOD}/>
                        </div>
                        <div className="patient_info_wrapper">
                            <div className="patient_info">
                                <div className="patient_info_block">
                                    <img className="icon" src={FA_ASSET_3}/>
                                    <div className="text_block">
                                        <p className="text">Patient name:</p>
                                        <p className="answer">Valo, Ville</p>
                                    </div>
                                </div>
                                <div className="patient_info_block">
                                    <img className="icon" src={FA_ASSET_5}/>
                                    <div className="text_block">
                                        <p className="text">Date of birth:</p>
                                        <p className="answer">1/7/1976</p>
                                    </div>
                                </div>
                                <div className="patient_info_block">
                                    <img className="icon" src={FA_ASSET_4}/>
                                    <div className="text_block">
                                        <p className="text">Patient ID:</p>
                                        <p className="answer">2401000098</p>
                                    </div>
                                </div>
                            </div>
                            <div className="patient_info">
                                <div className="patient_info_block">
                                    <img className="icon" src={FA_ASSET_7}/>
                                    <div className="text_block">
                                        <p className="text">Laboratory:</p>
                                        <p className="answer">Empire City Labs</p>
                                    </div>
                                </div>
                                <div className="patient_info_block">
                                    <img className="icon" src={FA_ASSET_6}/>
                                    <div className="text_block">
                                        <p className="text">Date:</p>
                                        <p className="answer">1/8/2024 12:10:41 PM</p>
                                    </div>
                                </div>
                                <div className="patient_info_block">
                                    <img className="icon" src={FA_ASSET_8}/>
                                    <div className="text_block">
                                        <p className="text">Tested foods:</p>
                                        <p className="answer">108</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="info">
                            <p>
                                Allergens are acquired either through the air (inhalation allergies) or by ingestion
                                (food allergies). Patients may develop a Type I hypersensitivity reaction resulting
                                from
                                the production of specific lgE antibodies. lgE antibodies are short-term (acute)
                                antibodies that circulate in the blood and may be associated with an anaphylactic
                                hypersensitivity response. Symptoms of these immediate-type reactions occur shortly
                                after contact with an allergen and may include rash, edema, itching, runny nose and
                                difficulty breathing.
                            </p>

                            <br/>

                            <p>
                                The <span>Euroline</span> inhalation and food allergy tests are semi-quantitative
                                tests
                                for the determination of inhalation-specific and food-specific IgE
                                antibodies. <span>Euroline</span> results are denoted by a color-coded scale from
                                0-6.
                            </p>

                            <br/>

                            <p>
                                This assay <strong>does not</strong> identify lgG antibodies which are long-term
                                antibodies and may be
                                associated with a delayed-onset (secondary) hypersensitivity response.
                            </p>

                            <br/>

                            <p>
                                This test has not been evaluated by the U.S. Food and Drug Administration and its
                                performance characteristics have been determined by Empire City Laboratories under
                                the
                                Clinical Laboratory Improvement Amendments (CLIA).
                            </p>
                        </div>
                        <div className="footer">
                            <img src={FOOTER_LOGO}/>
                        </div>
                    </div>
                </div>

                <div className="wrapper">
                    <div className="report">
                        <div className="header">
                            <img src={HEADER_REP_FOOD}/>
                        </div>
                        <div className="title">
                            <p>your personal results in detail</p>
                        </div>
                        <div className="patient_info_wrapper">
                            <div className="patient_info">
                                <div className="patient_info_block">
                                    <img className="icon" src={FA_ASSET_3}/>
                                    <div className="text_block">
                                        <p className="text">Patient name:</p>
                                        <p className="answer">Valo, Ville</p>
                                    </div>
                                </div>
                                <div className="patient_info_block">
                                    <img className="icon" src={FA_ASSET_4}/>
                                    <div className="text_block">
                                        <p className="text">Patient ID:</p>
                                        <p className="answer">2401000098</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <DisplayResults categorizedData={categorizedData} rasp={true}/>

                        <div className="footer">
                            <div className="footer_info">
                                <a>Interpretation of results:</a>
                                <a>Undetectable</a><img src={GREEN} alt={'image'}/>
                                <a>Moderate</a><img src={YELLOW} alt={'image'}/>
                                <a>High</a><img src={ORANGE} alt={'image'}/>
                                <a>Very high</a><img src={RED} alt={'image'}/>
                            </div>
                        </div>
                    </div>
                </div>

            </ReportBody>
        </PrintingDiv>
    );
});


const FoodAllergyReport = () => {
    const [loadData, setLoadData] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                setTimeout(resolve, 0);
            });
        },
        onAfterPrint: () => () => console.log('After print'),
    });


    return (
        <div>
            {/*<h1>`FoodAllergy` Reports</h1>*/}

            <Space>
                <Button size={'small'} type="dashed" icon={<EyeOutlined/>} onClick={() => {
                    setIsModalVisible(1);
                    setLoadData(true); // Set loadData to true when button is clicked
                }}>
                    Report
                </Button>
            </Space>

            <Modal
                // width={'100%'}
                width={'670px'}
                open={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
            >
                <Spin spinning={isLoading}>
                    <Row justify="center">

                        {error && <Col xs={24}><Alert message={error} type="error" showIcon/></Col>}

                        <Col xs={24}>
                            <Typography.Title level={4}>
                                <Space>
                                    Food Allergy Report
                                    <Button
                                        icon={<PrinterOutlined/>}
                                        onClick={handlePrint}
                                        size={'small'}>
                                        Report
                                    </Button>
                                </Space>
                            </Typography.Title>

                            <PrintableComponent
                                printHeaderVisible={false}
                                title={'To print'}
                                ref={componentRef}
                            />
                        </Col>
                    </Row>
                </Spin>

            </Modal>
        </div>
    )
}


const RaspReport = () => {
    const [loadData, setLoadData] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                setTimeout(resolve, 0);
            });
        },
        onAfterPrint: () => () => console.log('After print'),
    });


    return (
        <div>
            {/*<h1>`FoodAllergy` Reports</h1>*/}

            <Space>
                <Button size={'small'} type="dashed" icon={<EyeOutlined/>} onClick={() => {
                    setIsModalVisible(1);
                    setLoadData(true); // Set loadData to true when button is clicked
                }}>
                    Report
                </Button>
            </Space>

            <Modal
                // width={'100%'}
                width={'670px'}
                open={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
            >
                <Spin spinning={isLoading}>
                    <Row justify="center">

                        {error && <Col xs={24}><Alert message={error} type="error" showIcon/></Col>}

                        <Col xs={24}>
                            <Typography.Title level={4}>
                                <Space>
                                    Raspiratory Report
                                    <Button
                                        icon={<PrinterOutlined/>}
                                        onClick={handlePrint}
                                        size={'small'}>
                                        Report
                                    </Button>
                                </Space>
                            </Typography.Title>

                            <PrintableComponent2
                                printHeaderVisible={false}
                                title={'To print'}
                                ref={componentRef}
                            />
                        </Col>
                    </Row>
                </Spin>

            </Modal>
        </div>
    )
}


export {
    FoodAllergyReport, RaspReport
};